import { useAtom } from "jotai";
import {
  useBasicContract,
  useContract,
  useGetFuelPriceIndeces,
  usePolicyTypes,
} from "../api/queries";
import { userObjAtom } from "../state/state";
import { Link, useParams } from "react-router-dom";
import PolicyGraphType1or2 from "./PolicyGraphType1or2";
import ReferenceTable from "./ReferenceTable";
import {
  niceDate,
  niceMonthAndYear,
  nicePeriod,
  nicePeriodTable,
  niceXLabel,
} from "../helpers/generalFunctions.ts";
import { useEffect, useState } from "react";
import CoreTermsTable from "./CoreTermsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';


export const PageViewPolicy = () => {
  const [user] = useAtom(userObjAtom);
  const [lastPolicyId, setLastPolicyId] = useState(null);
  const [showProjected, setShowProjected] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [vehicleType, setVehicleType] = useState(null);
  const [processedData, setProcessedData] = useState([]);
  const [nonProcessedData, setNonProcessedData] = useState([]);
  const [viewingHash, setViewingHash] = useState(null);


  let { id } = useParams();

  const contract = useContract({ id: id, userId: user?.id });

  const policyTypes = usePolicyTypes();

  console.log("policytypes", policyTypes);
  console.log("contract", contract);

  const summary = contract?.data?.contract_summary.contracts_summary;
  const currentPeriod = contract?.data?.current_period_info;
  console.log("summary", summary);
  const policyTypeID = contract?.data?.func_1?.contracts_1?.policy_types_id;
  const isLive =
    contract?.data?.func_1?.contracts_1?.pending === true ? false : true;


  useEffect(() => {
    // reset when id changes
    if (id !== lastPolicyId) {
      console.log("reset");
      setVehicleType(null);
      setLastPolicyId(id);
      setShowVehicleModal(false);
      setProcessedData([]);
      setViewingHash(null);
    }
  }, [id]);

  useEffect(() => {
    if (vehicleType === null && [3, 4].includes(policyTypeID)) {
      setShowVehicleModal(true);
    } else {
      setShowVehicleModal(false);
    }
  }, [policyTypeID, vehicleType]);

  const graphData = contract?.data?.func_1?.result_1;

  console.log("processed", processedData);
  useEffect(() => {
    // if it's a vehicle, shows specific
    if (graphData) {
      const tempdata = JSON.parse(JSON.stringify(graphData));

      console.log(tempdata);

      const converted = tempdata.map((d, i) => {
        d.per = d.adjustment_pcnt * 100;
        if (vehicleType != null) {
          d.per =
            d.vehicle_type_output.find((v) => v.value.id === vehicleType).value
              .adjustment_amount * 100;
        }
        return d;
      });

      let filteredConverted = [];
      converted.forEach(item => {
        if (item.actual_vs_forecast_vs_projection === 'actual' || showProjected === true) {
          filteredConverted.push(item);
        }
      });

      let filteredRaw = [];
      graphData.forEach(item => {
        if (item.actual_vs_forecast_vs_projection === 'actual' || showProjected === true) {
          filteredRaw.push(item);
        }
      });

      console.log('filtererRaw', filteredRaw);

      setProcessedData(filteredConverted);
      setNonProcessedData(filteredRaw);
    }
  }, [graphData, vehicleType, showProjected]);


  useEffect(() => {

    if (contract?.data?.func_1?.contracts_1?.viewing_hash){
      setViewingHash(contract?.data?.func_1?.contracts_1?.viewing_hash);
    } 

  }, [contract])

  if (!id) {
    return <p>No contract id provided</p>;
  }

  if (
    contract.isLoading ||
    policyTypes.isLoading ||
    !summary ||
    id !== lastPolicyId
  ) {
    return (
      <div>
        <p>
          <FontAwesomeIcon spin icon={faSpinner} className="text-md mr-2" />
          Loading
        </p>
      </div>
    );
  }

  const generateShareLink = async () => {
    axios
      .put(
        `https://api.fastfloat.io/api:y81pgVS5/contracts-share-hash/${id}`,
        {
          'viewing_hash': uuidv4(),
        }
      )
      .then(function (response) {
        console.log(response);
        console.log(response.data, 'response.data')
        setViewingHash(response.data);
        toast.success("Public link created");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <>
      {!viewingHash && (
        <div class="flex flex-row justify-end">
          <button class=" ml-2 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center" onClick={() => { generateShareLink() }}>Create share link</button>
        </div>
      )}
      {viewingHash && (

        <div class="flex flex-row justify-end">
          <input className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
            type="text" value={`https://dev.fastfloat.io/public/${viewingHash}`} />

          <button className=" ml-2 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
            onClick={() => {
              navigator.clipboard.writeText(`https://dev.fastfloat.io/public/${viewingHash}`);
              toast.success("Copied to clipboard");
            }}
          >
            Copy
          </button>
        </div>
      )} 

      <div className="mt-4 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <div className="text-center relative">
          <h1 className="text-2xl font-bold text-gray-900">
            {summary.contract_display_name}
          </h1>
          <Link
            className="absolute left-2 top-0 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-2 w-full sm:w-auto text-center"
            to={`/policies/edit/${id}`}
          >
            Edit
          </Link>

          <button className="absolute underline right-2 top-0" onClick={() => { setShowProjected(!showProjected) }}>{showProjected === true ? 'Hide projections' : 'Show projections'}</button>


          <p>
            <span className="text-gray-500">Fuel index:</span>{" "}
            {summary.index_name}
          </p>
          <p>
            <span className="text-gray-500">Type:</span>{" "}
            {policyTypes.data.find((p) => p.id === policyTypeID)?.type_name}
          </p>
          {/* <p><span className="text-gray-500">Type:</span>{isLive ? 'Pending' : 'Published'}</p> */}

          {[3, 4].includes(policyTypeID) && (
            <button
              className="underline absolute top-8 right-2"
              onClick={() => {
                setShowVehicleModal(true);
                setVehicleType(null);
              }}
            >
              {vehicleType === null
                ? "Choose vehicle"
                : "Vehicle: " + currentPeriod?.vehicle_type_output?.find(
                  (f) => f.value.id === vehicleType
                )?.value?.group_name}
            </button>
          )}
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        <div className="mt-4 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          {currentPeriod !== null && (
            <>
              <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
                {summary.update_frequency === "quarter" && (
                  <>
                    Fuel price for current period: Quarter starting{" "}
                    {niceMonthAndYear(currentPeriod.period1)}
                  </>
                )}
                {summary.update_frequency !== "quarter" && (
                  <>
                    Fuel price for current period:{" "}
                    {niceMonthAndYear(currentPeriod.period2)}
                  </>
                )}
              </h3>
              <h2 className="text-3xl font-bold mb-4">
                {currentPeriod.currency_used_for_policy}{" "}
                {currentPeriod.fuel_price_for_required_period.toFixed(2)}
              </h2>
              {summary?.benchmark_price && (
                <p className="text-sm font-normal text-gray-500">
                  Compared to {currentPeriod.currency_used_for_policy}{" "}
                  {summary.benchmark_price.toFixed(2)} reference price
                </p>
              )}
            </>
          )}
          {currentPeriod === null && (
            <>
              <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
                Current period fuel data not available
              </h3>
            </>
          )}
        </div>

        <div className="mt-4 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          {currentPeriod != null && (
            <>
              <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
                {summary.update_frequency === "quarter" && (
                  <>
                    Fuel policy adjustment for current period: Quarter starting{" "}
                    {niceMonthAndYear(currentPeriod.period1)}
                  </>
                )}
                {summary.update_frequency !== "quarter" && (
                  <>
                    Fuel policy adjustment for current period:{" "}
                    {niceMonthAndYear(currentPeriod.period2)}
                  </>
                )}
              </h3>
              <h2 className="text-3xl font-bold mb-4">
                {currentPeriod.adjustment_pcnt >= 0 ? "+" : ""}
                {(currentPeriod.adjustment_pcnt * 100).toFixed(2)}%
              </h2>
            </>
          )}
          {/* <p className="text-sm font-normal text-gray-500">Compared to [+2.6%] IN [PRIOR PERIOD] - use previous months value and work it out</p> */}
          {currentPeriod === null && (
            <>
              <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
                Current period fuel data not available
              </h3>
            </>
          )}
        </div>

        {/* <div className="mt-4 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
            Revised price calculator
          </h3>
        </div> */}
      </div>

      <div className="mt-4 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        {/* <PolicyGraphType1or2 graphData={graphData} /> */}
        <PolicyGraphType1or2
          refPrice={summary.benchmark_price}
          vehicleType={vehicleType}
          id={id}
          graphData={nonProcessedData}
          frequency={summary.update_frequency}
          xLabel={niceXLabel(summary.update_frequency)}
          xLabel2={
            [1, 2].includes(policyTypeID)
              ? "Fuel Adjustment %"
              : `Per ${summary.distance_unit} adjustment`
          }
        />
      </div>

      <div className="mt-4 bg-white text-xs shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr class="text-center">
                <th scope="col" class="px-4 py-4 w-[12.5%]">
                  {summary.update_frequency === "week" && (
                    <>Shipment date (week ending)</>
                  )}
                  {summary.update_frequency === "month" && <>Shipment month</>}
                  {summary.update_frequency === "quarter" && (
                    <>Shipment month</>
                  )}
                </th>

                {summary.update_frequency === "quarter" && (
                  <th scope="col" class="px-4 py-4 w-[12.5%]">
                    Quarter starting
                  </th>
                )}
                <th scope="col" class="px-4 py-4 w-[12.5%]">
                  {summary.comparison_window < 2 && <>Fuel Price Period</>}
                  {summary.comparison_window > 1 && (
                    <>Fuel Price Period - From</>
                  )}
                </th>
                {summary.comparison_window > 1 && (
                  <th scope="col" class="px-4 py-4 w-[12.5%]">
                    Fuel Price Period - End
                  </th>
                )}
                <th scope="col" class="px-4 py-4 w-[12.5%]">
                  Average Fuel Price for period
                </th>
                {[1, 2].includes(policyTypeID) && (
                  <th scope="col" class="px-4 py-4 w-[12.5%]">
                    Fuel Adjustment %
                  </th>
                )}
                {[3, 4].includes(policyTypeID) && (
                  <th scope="col" class="px-4 py-4 w-[12.5%]">
                    Per {summary.distance_unit} adjustment
                  </th>
                )}
                <th scope="col" class="px-4 py-4 w-[12.5%]">
                  Actuals / Projects / Forecast
                </th>
              </tr>
            </thead>
            <tbody>
              {processedData.map((g) => (
                <tr
                  class={`${g.current_period_boolean ? "bg-green-100" : "bg-white"
                    } hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700`}
                >
                  <td class="px-4 py-4">
                    {nicePeriodTable(g.period1, summary.update_frequency)}
                  </td>
                  {summary.update_frequency === "quarter" && (
                    <td class="px-4 py-4">
                      {nicePeriodTable(g.period2, summary.update_frequency)}
                    </td>
                  )}
                  <td class="px-4 py-4">
                    {nicePeriod(
                      g.period_fuel_price_start_assessment,
                      summary.update_frequency
                    )}
                  </td>
                  {summary.comparison_window > 1 && (
                    <td class="px-4 py-4">
                      {nicePeriod(
                        g.period_fuel_price_end_assessment,
                        summary.update_frequency
                      )}
                    </td>
                  )}
                  <td class="px-4 py-4">
                    {g.currency_used_for_policy}{" "}
                    {g.fuel_price_for_required_period.toFixed(2)}
                  </td>
                  {[1, 2].includes(policyTypeID) && (
                    <td class="px-4 py-4">{g.per.toFixed(2)} %</td>
                  )}
                  {[3, 4].includes(policyTypeID) && (
                    <td class="px-4 py-4">
                      {g.currency_used_for_policy} {g.per.toFixed(2)}
                    </td>
                  )}
                  <td class="px-4 py-4 capitalize">
                    {g.actual_vs_forecast_vs_projection}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        {summary?.reference_table && (
          <div className="mt-4 bg-white text-xs shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
              Reference table
            </h3>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <ReferenceTable summary={summary} />
            </div>
          </div>
        )}

        {summary && (
          <div className="mt-4 bg-white text-xs shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
              Core Policy Terms
            </h3>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <CoreTermsTable summary={summary} />
            </div>
          </div>
        )}

        <div className="mt-4 bg-white text-xs shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
            Disclaimer
          </h3>

          <p className="text-sm font-normal text-gray-500">
            Whilst FastFloat try to ensure that the provided data is accurate at
            all times, they bear no responsibility for invoicing errors that
            arise as a result of incorrect data
          </p>
        </div>
      </div>
      {
        showVehicleModal && (
          <div
            className={
              "fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 flex items-center justify-center"
            }
          >
            <div className="w-1/2 p-8 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
                Choose vehicle type
              </h3>
              {currentPeriod?.vehicle_type_output?.map(
                (vehicle) => (
                  <button
                    type="button"
                    onClick={() => setVehicleType(vehicle.value.id)}
                    className="mr-2 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-2 mb-2"
                  >
                    {vehicle.value.group_name}
                  </button>
                )
              )}
            </div>
          </div>
        )
      }
    </>
  );
};
