import { useAtom } from "jotai";
import { useTranslations } from "../api/queries";
import { browserLangString } from "../state/state";

const useLang  = () => {
    const language = useTranslations();
    const [browserLang] = useAtom(browserLangString);

    const lang = (key) => {
        return language.data?.items?.find(item => item.key === key)?.[browserLang] || key;
    }

    return {
        lang
    }

}

export default useLang;