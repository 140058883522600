import axios from "axios";
import Logo from "../images/fastfloat.png";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { tokenStringAtom } from "../state/state";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast";


export const PageConfirmEmail = () => {

    let { id } = useParams();
    const [token, setToken] = useAtom(tokenStringAtom);
    const navigate = useNavigate()

    console.log(id);

    useEffect(() => {
        axios.post('https://api.fastfloat.io/api:y81pgVS5/auth/hash', {
            hash: id,
        })
            .then(function (response) {
                console.log(response);

                if (response.status === 200 && response.data.authToken) {
                    setToken(response.data.authToken);
                }

            })
            .catch(function (error) {
                toast.error('Your authentication link is no longer valid');
            });
    }, [id])


    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token])


    return (
        <main className="bg-gray-50">

            <div className="mx-auto min-h-screen flex flex-col justify-center items-center px-6 pt-8 pt:mt-0">
                <img src={Logo} className="w-64 mb-8" alt="FastFloat Logo" />

                {/* <!-- Card --> */}
                <div className="bg-white shadow rounded-lg md:mt-0 w-full sm:max-w-screen-sm xl:p-0 mb-16">
                    <div className="p-6 sm:p-8 lg:p-16 space-y-8">

                        <>
                            <h2 className="text-2xl lg:text-3xl font-bold text-gray-900">
                                Thanks for confirming
                            </h2>
                            <p>Logging you in</p>
                        </>



                    </div>
                </div>
            </div>
        </main>
    )

}