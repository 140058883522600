import language from "../language.json";

export const lang = (key, lang) => {
    
    if (!lang) {
        console.log('missing lang param for ', key);
        lang = 'en'
    }

    if (!key) {
        return '';
    }

    let transObj = language.find(l => l.key === key)

    if (transObj) {
        return transObj[lang].replace(/[\r\n]+/g, '\n\n')
    } else {
        return 'No translation for ' + key
    }

}