import { Link } from "react-router-dom";
import { useContractComparison, useContractsCreatedByUser, useFuelPriceIndecesListSummary, useGetFuelPriceIndeces, usePolicyTypes } from "../api/queries";
import { useEffect, useState } from "react";
import { niceDate } from "../helpers/generalFunctions.ts";
import { SearchTable } from "./SearchTable.tsx";
import { comparePolicyArrayAtom } from "../state/state";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faSpinner } from "@fortawesome/free-solid-svg-icons";

export const PageSubscribedPolicies = () => {

  const priceSummary = useFuelPriceIndecesListSummary();
  const contracts = useContractsCreatedByUser()
  const [sorted, setSorted] = useState([])
  const policyTypes = usePolicyTypes();
  const indeces = useGetFuelPriceIndeces();
  const [compareArr] = useAtom(comparePolicyArrayAtom)
  const [localCompareArr, setLocalCompareArr] = useState([]);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [downloadPending, setDownloadPending] = useState(false);
  const [months, setMonths] = useState(3);
  console.log('co', contracts);

  const comparison = useContractComparison({ idArray: localCompareArr, months });


  useEffect(() => {
    if (contracts.isFetched && contracts?.data?.items?.length > 0) {
      const copy = JSON.parse(JSON.stringify(contracts?.data?.items));
      const sorted = copy.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.updated_at) - new Date(a.updated_at);
      });

      setSorted(sorted);

    }
  }, [contracts.isFetched])

  console.log(sorted);

  useEffect(() => {

    if (comparison?.isFetched && comparison?.data?.length > 0 && !hasDownloaded) {
      console.log('comparison', comparison);
      // generate csv to download
      let csv = 'Contract ID,Contract Name,Period Floater,Fuel Price,Adjustment Percent,Internal Code\n';
      comparison?.data?.map(c => {
        csv += `${c.contract_id},"${c.contract_display_name}",${c.period_floater},${c.fuel_price_for_required_period},${c.adjustment_pcnt},"${c.internal_linking_code.replace(/(?:\r\n|\r|\n)/g, '\u2028')}"\n`
      })

      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'comparison.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadPending(false);
      // end csv download
      setHasDownloaded(true);

    }

  }, [comparison])


  const compareResults = () => {
    console.log('compare');
    setLocalCompareArr(compareArr);
    setHasDownloaded(false);
    setDownloadPending(true);

  }

  return (
    <>
      <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">Policies</h1>

      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 pb-20 sm:pb-20 xl:pb-20 relative">

        <h2 className="text-xl leading-none font-bold text-gray-900 mb-4">My created policies</h2>

        <div className="absolute right-8 top-4 flex flex-row">
          <select onChange={(e) => setMonths(e.target.value)} className="w-32 mr-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-1 mt-0" name="comparison" id="comparison">
            <option value={3}>Past 3 months</option>
            <option value={6}>Past 6 months</option>
            <option value={9}>Past 9 months</option>
            <option value={12}>Past 12 months</option>
          </select>
          <div>
            <button
              disabled={downloadPending}
              className={`text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-2 w-full sm:w-auto text-center ${compareArr.length > 0 && !downloadPending ? 'opacity-100' : 'opacity-50 pointer-events-none'}`}
              onClick={() => { compareResults() }}
            >
              Compare and Export Results {downloadPending && <FontAwesomeIcon icon={faSpinner} spin className="text-lg ml-2" />}{!downloadPending && <FontAwesomeIcon icon={faFileCsv} className="text-lg ml-2" />}
            </button>
          </div>
        </div>
        {sorted.length > 0 && policyTypes.isFetched && indeces.isFetched &&
          <SearchTable data={sorted} policyTypes={policyTypes} indeces={indeces} />
        }
        {/* <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">

            <tr class="text-center">
              <th scope="col" class="px-4 py-4">Contract name</th>
              <th scope="col" class="px-4 py-4">Policy type</th>
              <th scope="col" class="px-4 py-4">Fuel price index</th>
              <th scope="col" class="px-4 py-4">Benchmark price</th>
              <th scope="col" class="px-4 py-4">Update frequency</th>
              <th scope="col" class="px-4 py-4">Start date</th>

              <th scope="col" class="px-4 py-4"></th>
            </tr>
          </thead>
          <tbody>

            {sorted?.map(p => (
              <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-4 py-4">{p.contract_display_name}</td>
                <td class="px-4 py-4">{p.policy_types_id}</td>
                <td class="px-4 py-4">{p.fuel_price_indeces_id}</td>
                <td class="px-4 py-4">{parseFloat(p.benchmark_price).toFixed(2)}<br />{p.baseline_price_comment}</td>
                <td class="px-4 py-4">{p.update_frequency}</td>
                <td class="px-4 py-4">{niceDate(p.contract_start_date)}</td>


                <td><button class="ml-4 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center" onClick={() => {
                  // setShowPriceModal(false);
                  // setPolicy({ ...policy, 'index_name': p.id })


                }}>Select</button></td>
              </tr>

            ))}
          </tbody>
        </table> */}
      </div>




    </>
  );
};

