import { niceDate } from "../helpers/generalFunctions.ts";

const CoreTermsTable = ({ summary }) => {
    const data = summary

    if (!data) {
        return <></>
    }
    return (
        <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">

                <tr class="text-center">
                    <th scope="col" class="px-4 py-4 w-[50%]">Policy parameter</th>
                    <th scope="col" class="px-4 py-4 w-[50%]">Value</th>

                </tr>
            </thead>
            <tbody>
                {data?.type_name != null &&
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Policy type</td>
                        <td class="px-4 py-4">{data.type_name}</td>

                    </tr>
                }
                {data?.contract_display_name != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Policy name</td>
                        <td class="px-4 py-4">{data.contract_display_name}</td>

                    </tr>
                }

                {data?.update_frequency != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Rate update frequency</td>
                        <td class="px-4 py-4">{data.update_frequency}</td>

                    </tr>
                }
                {data?.index_name != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Fuel price index</td>
                        <td class="px-4 py-4">{data.index_name}</td>

                    </tr>
                }

                {data?.data_source_url != null &&
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Fuel price info data source</td>
                        <td class="px-4 py-4"><a className="underline" target="_blank" href={data.data_source_url}>{data.data_source_url}</a></td>

                    </tr>
                }

                {data?.code != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Currency code</td>
                        <td class="px-4 py-4">{data.code}</td>

                    </tr>
                }

                {data?.benchmark_price != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Reference price</td>
                        <td class="px-4 py-4">{data.benchmark_price}</td>

                    </tr>
                }

                {data?.lag_period != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Lag period</td>
                        <td class="px-4 py-4">{data.lag_period}</td>

                    </tr>

                }
                {data?.comparison_window != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Comparison window</td>
                        <td class="px-4 py-4">{data.comparison_window}</td>

                    </tr>
                }

                {data?.fuel_share_pcnt != null &&


                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Fuel share percent</td>
                        <td class="px-4 py-4">{Math.round(data.fuel_share_pcnt * 100)}%</td>

                    </tr>

                }

                {data?.minimum_change_from_baseline != null &&


                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Minimum change from baseline (+-)</td>
                        <td class="px-4 py-4">{Math.round(data.minimum_change_from_baseline * 100)}%</td>

                    </tr>
                }

                {data?.contract_start_date != null &&
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Contract start date</td>
                        <td class="px-4 py-4">{niceDate(data.contract_start_date)}</td>

                    </tr>
                }
                {data?.contract_end_date != null &&

                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Contract end date</td>
                        <td class="px-4 py-4">{niceDate(data.contract_end_date)}</td>

                    </tr>

                }


                {data?.date_based_on != null &&
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Shipment date based on</td>
                        <td class="px-4 py-4">{data.date_based_on}</td>

                    </tr>
                }

                {data?.further_info_url &&
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">Further information</td>
                        <td class="px-4 py-4"><a className="underline" target="_blank" href={data.further_info_url}>{data.further_info_url}</a></td>

                    </tr>
                }



            </tbody>
        </table>
    )
}

export default CoreTermsTable;