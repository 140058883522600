import knlogo from "../images/knlogo.png";

export const PageKN = () => {

  return (
    <>
      <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">Policies</h1>
      <div class="w-full mb-4 grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 pb-20 sm:pb-20 xl:pb-20 relative">
          <p>Content here</p>
        </div>
      </div>
    </>
  );
};

