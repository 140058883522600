import { useAtom } from "jotai";
import {
  useBasicContract,
  useContract,
  useContractInputHelper,
  useCurrencies,
  useFuelPriceDataForBenchmark,
  useFuelPriceIndecesListSummary,
  useGetFuelPriceIndeces,
  usePolicyTypes,
} from "../../api/queries";
import { newPolicyObjAtom, userObjAtom } from "../../state/state";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import useLang from "../../components/useLang";
import "react-tooltip/dist/react-tooltip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faInfoCircle,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getPolicyNameFromId, niceDate, nicePeriodTable } from "../../helpers/generalFunctions.ts";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

export const PageManualCreatePolicyStep2 = () => {
  let { id } = useParams();

  const [run, setRun] = useState(false);

  const [steps, setSteps] = useState([
    {
      target: 'body',
      content: <div className="text-left">
        <h1 class="text-2xl leading-none font-bold text-gray-900 mb-4">Creating a policy</h1>
        <p class="mb-4">We'll quickly take you through the steps to creating a policy.</p>
      </div>,
      placement: 'center',
    },
    {
      target: '#contract_display_name',
      content: <div className="text-left">
        First step is to give your policy a name - this is what anyone viewing your policy will see, so we recommend choosing a name that makes it clear what it relates to
      </div>,
    },
    {
      target: '#index_name',
      content: <div className="text-left">
        <p className="mb-4">Next is choosing the fuel price index that your policy will use. FastFloat currently supports over 100, including all EU Bulletin Country indexes.</p>

        <p className="mb-4">Either select from the drop down list or click 'Show Price Indeces' to see more details alongside latest prices.</p>

        <p>If you're creating a policy from scratch rather than recreating an exisitng one, take a look at our help section on "How to choose the right fuel price index"</p>
      </div>,
    },
    {
      target: '#currency_name',
      content: <div className="text-left">
        <p className="mb-4">Currency can either be the currency of the index itself (e.g. a UK index would be in GBP) or alternatively, all indexes are available in EUR values.</p>

        <p className="mb-4">EUR based indexes are only availabe in EUR.</p>
      </div>,
    },
    {
      target: '#benchmark_price',
      content: <div className="text-left">
        <p className="mb-4">The benchmark price (or reference price) is the price that you compare the current fuel price to when making the fuel adjustments.
        </p>
        <p className="mb-4">Typically, this will be the price from a recent month / quarter, but is occasionally set at a level from many years in the past for the continuinty purposes.
        </p>
        <p className="mb-4">Clicking "show historic prices" allows you to see historic prices for this index and select the appropriate benchmark price.
        </p>
        <p className="mb-4">Note : ensure you enter the EUR (or equivalent figure) per litre, i.e. for 140c per litre, enter 1.40!</p>
      </div>,
    },
    {
      target: '#baseline_price_comment',
      content: <div className="text-left">
        <p className="mb-4">This lets viewers know what period the benchmark price relates to (e.g. "Benchmark price = Q4 2023 average price")</p>
      </div>,
    },
    {
      target: '#update_frequency',
      content: <div className="text-left">
        <p className="mb-4">Determines whether the policy is a weekly, monthly or quarterly updated policy.
        </p>

        <p className="mb-4">Note: Some indexes are only updated on a monthly basis - in this case, only monthly and quarterly will be available</p>
      </div>,
    },
    {
      target: '#lag_period',
      content: <div className="text-left">
        <p className="mb-4">Policies almost <i>always</i> operate with a lag - e.g. the adjustment for this month is based on last months fuel prices rather than this months, so that the sucharge is known at the time of shipment/invoicing.
        </p>
        <p className="mb-4">A value of 1 here would indicate that it's the prior month (or week if weekly update frequency).</p>

      </div>,
    },
    {
      target: '#fuel_share_pcnt',
      content: <div className="text-left">
        <p className="mb-4">And finally, the "fuel share %" - this is the % of a shipments cost that is assumed to be related to fuel.
        </p>
        <p className="mb-4">Typically this ranges from 20% to 35% for a FTL shipment, varying dependent on distance and truck type.</p>

      </div>,
    },
    {
      target: '#accordion-collapse',
      content: <div className="text-left">
        <p className="mb-4">Various other advanced settings are available to ensure users are able to replicate any common Benchmark Policy type (e.g. minimum and maximum adjustments, decimal point rounding, additional notes).
        </p>

      </div>,
    },
    {
      target: 'body',
      content: <div className="text-left">
        <p className="mb-4">You'd then click create policy and your policy would then be generated in the FastFloat system.
        </p>
        <p className="mb-4">And that's it! Details of the differences in how to create other fuel policy types can be seen in the help guide or on the policy create screens themselves.
        </p>
        <p className="mb-4">Fuel adjustments are calculated as soon as new fuel price info is available in the FastFloat system and can be accessed through viewing the policy itself or through a CSV / Excel output where you can see the adjustments for multiple policies at the same time.
        </p>
        <p className="mb-4">Sharing a policy with a carrier, shipper or broker is simply a case of loading the policy and clicking "Create share link" at the top right of the screen</p>

      </div>,
      placement: 'center',
    },
  ]);



  const policyTypes = usePolicyTypes();
  const [policy, setPolicy] = useAtom(newPolicyObjAtom);
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const l = useLang();

  const [showAdvanced, setShowAdvanced] = useState(false);
  const fuelIndeces = useGetFuelPriceIndeces();

  const [user] = useAtom(userObjAtom);

  const currencies = useCurrencies();
  const priceSummary = useFuelPriceIndecesListSummary();
  const policyInputHelper = useContractInputHelper({
    typeId: policy.policyType,
  });
  const navigate = useNavigate();
  const contract = useBasicContract({ id: id, userId: user?.id });

  console.log("ps", priceSummary);

  console.log(currencies);
  console.log("helper", policyInputHelper);

  console.log("policy", policy);

  console.log("fuel", fuelIndeces);

  const fuelPriceDataBenchmark = useFuelPriceDataForBenchmark({
    id: policy?.index_name,
  });

  console.log("fpdb", fuelPriceDataBenchmark);

  const localCurrency = fuelIndeces?.data?.find(
    (f) => f.id === parseInt(policy?.index_name, 10)
  )?.index_base_currency_code;

  console.log(localCurrency, "local");

  const benchMarkModal = () => {
    if (policy?.index_name === undefined) {
      alert("Please select a fuel index first");
    }

    setShowBenchmarkModal(true);
  };


  const renderHtml = (f, dropDownOptions) => {
    return (
      <div
        key={f.id}
        id={f.Database_column_name}
        className="mb-4 max-w-2xl border-b border-grey-100 pb-6"
      >
        <p className="text-xl leading-none font-bold text-gray-900 mb-1">
          {/* {f.WeWeb_display_name}
          {f.Database_column_name} */}
          {l.lang(f.Database_column_name)}
          <span
            className="my-anchor-element"
            data-tooltip-content={l.lang(`${f.Database_column_name}_info`)}
          // data-tooltip-place="top"
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-xl ml-2"
            />
          </span>
        </p>
        {/* <p className="text-sm font-normal text-gray-500 mb-2">{f.WeWeb_helper_text}</p> */}
        {(f.Type === "Free text" || !f.Type) && (
          <input
            value={policy?.[f.Database_column_name]}
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value,
              })
            }
            placeholder={l.lang(f.Database_column_name)}
            type="text"
            className="lg:w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
          />
        )}
        {(f.Type === "json") && (
          <>
            <textarea
              className="lg:w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
              defaultValue={policy?.[f.Database_column_name]} onChange={(e) =>
                setPolicy({
                  ...policy,
                  [f.Database_column_name]: e.target.value,
                })
              } rows={10} wrap="soft"></textarea>
            {f.Database_column_name === "reference_table_json_input" && (
              <div className="mt-2">
                <a className="underline " href="https://docs.google.com/spreadsheets/d/1JyHNK4JNjRR4CE-rbH-JMzxhdVQvhgr0TMN2HTP-CMM/edit#gid=1795675329" target="_blank" rel="noreferrer">Reference table parameter generator</a>
              </div>
            )}
          </>


        )}
        {f.Type === "date picker" && (
          <input
            type="date"
            value={policy?.[f.Database_column_name]}
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value,
              })
            }
            placeholder={l.lang(f.Database_column_name)}
            className="lg:w-1/4 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
          />
        )}
        {f.Type === "decimal" && (
          <input
            onWheel={(event) => event.currentTarget.blur()}
            type="number"
            value={policy?.[f.Database_column_name]}
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value,
              })
            }
            placeholder={"0.00"}
            className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
          />
        )}
        {f.Type === "percent" && (
          <div className="mt-2 flex flex-row justify-items-center items-center">
            <div>
              <input
                onWheel={(event) => event.currentTarget.blur()}
                type="number"
                value={policy?.[f.Database_column_name] * 100}
                onChange={(e) =>
                  setPolicy({
                    ...policy,
                    [f.Database_column_name]: e.target.value / 100,
                  })
                }
                placeholder={"0.00"}
                className="lg:w-16 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
              /></div>
            <div className="ml-1">%</div>
          </div>

        )}
        {f.Type === "integer" && (
          <input
            onWheel={(event) => event.currentTarget.blur()}
            min="0"
            step="1"
            type="number"
            value={policy?.[f.Database_column_name]}
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value,
              })
            }
            placeholder={"0"}
            className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
          />
        )}
        {f.Type === "boolean" && (
          <select
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value === "true",
              })
            }
            className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4"
            name="boolean"
            id="boolean"
          >
            <option
              disabled
              selected={policy?.[f.Database_column_name] === undefined}
            >
              {l.lang('input_select_option')}
            </option>

            <option
              value={true}
              selected={true === policy?.[f.Database_column_name]}
            >
              True
            </option>
            <option
              value={false}
              selected={false === policy?.[f.Database_column_name]}
            >
              False
            </option>
          </select>
        )}
        {f.Type === "drop_down" && (
          <select
            onChange={(e) =>
              setPolicy({
                ...policy,
                [f.Database_column_name]: e.target.value,
              })
            }
            className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4"
            name="comparison"
            id="comparison"
          >
            <option
              disabled
              selected={policy?.[f.Database_column_name] === undefined}
            >
              {l.lang('input_select_option')}
            </option>
            {f.Database_column_name === "index_name" &&
              // Fuel index options
              fuelIndeces?.data.map((fi) => (
                <option
                  value={fi.Index_id}
                  selected={fi.Index_id === policy.index_name}
                >
                  {fi.index_name}
                </option>
              ))}
            {f.Database_column_name === "currency_name" &&
              // Fuel index options

              currencies?.data.map((fi) => {
                if (["EUR", localCurrency].includes(fi.code)) {
                  return (
                    <option
                      value={fi.id}
                      selected={fi.id === policy.currency_name}
                    >
                      {fi.name} ({fi.code})
                    </option>
                  );
                }
              })}

            {!["currency_name", "index_name"].includes(
              f.Database_column_name
            ) &&
              // normal options

              dropDownOptions.map((o) => (
                <option
                  value={o}
                  selected={o === policy?.[f.Database_column_name]}
                >
                  {o}
                </option>
              ))}
          </select>
        )}
        {f.Database_column_name === "benchmark_price" && (
          <button
            disabled={
              policy?.index_name === undefined ||
              !fuelPriceDataBenchmark?.data
            }
            type="button"
            onClick={() => benchMarkModal()}
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-4 mb-4 disabled:opacity-50"
          >
            {l.lang('button_show_historic_prices')}{" "}
            {fuelPriceDataBenchmark.isLoading && (
              <FontAwesomeIcon
                spin
                icon={faSpinner}
                className="text-sm ml-2"
              />
            )}
          </button>
        )}
        {f.Database_column_name === "index_name" && (
          <button
            disabled={!priceSummary?.data}
            type="button"
            onClick={() => setShowPriceModal(true)}
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-4 mb-4 disabled:opacity-50"
          >
            {l.lang('button_show_price_indeces')}{" "}
            {priceSummary.isLoading && (
              <FontAwesomeIcon
                spin
                icon={faSpinner}
                className="text-sm ml-2"
              />
            )}
          </button>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (id && contract.isFetched && !policy?.id) {
      console.log("FETCHED", contract);
      const summary = contract.data;
      summary.policyType = summary.policy_types_id;
      summary.index_name = summary.fuel_price_indeces_id;
      summary.currency_name = summary.currencies_id;

      setPolicy(summary);
    }
  }, [id, contract]);

  useEffect(() => {
    console.log("HEREE");
    return () => {
      setPolicy({});
    };
  }, []);

  const updateData = () => {

    if (policy.policyType === 2) {

      if (!isJsonString(policy.reference_table_json_input)) {
        toast.error("You have not entered valid JSON for the reference table.");
        return;
      }
    }
    console.log(policy);

    let obj = {
      contracts_id: policy.id,
      users_id: user.id,
      contract_display_name: policy.contract_display_name,
      internal_linking_code: "",
      policy_types_id: policy.policyType,
      fuel_price_indeces_id: policy.index_name,
      contract_start_date: policy.contract_start_date,
      contract_end_date: policy.contract_end_date,
      currencies_id: policy.currency_name,
      benchmark_price: policy.benchmark_price,
      baseline_price_comment: policy.baseline_price_comment,
      update_frequency: policy.update_frequency,
      update_frequency_option: policy.update_frequency_option,
      lag_period: policy.lag_period,
      comparison_window: policy.comparison_window,
      fuel_share_pcnt: policy.fuel_share_pcnt,
      minimum_change_from_baseline: policy.minimum_change_from_baseline,
      dp_rounding: policy.dp_rounding,
      reference_table_2nd_from_price: null,
      reference_table_last_from_price: null,
      reference_table_fuel_price_steps: null,
      reference_table_adjustment_per_step: null,
      reference_table_json_input: policy?.reference_table_json_input || null,
      carrier_verification_required: policy.carrier_verification_required,
      rate_verification_required: policy.rate_verification_required,
      rate_verification_start_date: null,
      supplier_linking_codes: null,
      placeholder_column_1: null,
      placeholder_column_2: null,
      creation_source: "",
      min_adjustment: policy.min_adjustment,
      max_adjustment: policy.max_adjustment,
      pending: true,
      distance_unit: "",
      test_policy_delete_at_date: null,
      created_at: null,
      updated_at: null,
      policy_direction: policy.policy_direction,
      contract_display_notes: policy.contract_display_notes,
      users_id_specific: null,
      day_policy_applies_from: policy.day_policy_applies_from || "monday",
      minimum_step_increment: policy.minimum_step_increment,
      date_based_on: policy.date_based_on,
      further_info_url: "",
      per_km_vehicle_type_consumption: null,
    };

    console.log("OBJ", obj);

    axios
      .post(
        `https://api.fastfloat.io/api:y81pgVS5/contract_edit/${policy.id}`,
        obj
      )
      .then(function (response) {
        console.log(response);
        toast.success("Updated policy");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const sendData = () => {
    console.log(policy);

    if (policy.policyType === 2) {

      if (!isJsonString(policy.reference_table_json_input)) {
        toast.error("You have not entered valid JSON for the reference table.");
        return;
      }
    }


    //   {
    //     "policyType": 1,
    //     "policyTypeName": "Benchmark price (% adjustment)",
    //     "contract_display_name": "My new policy",
    //     "contract_start_date": "2023-04-07",
    //     "contract_end_date": "2023-04-15",
    //     "index_name": 7,
    //     "currency_name": "EUR",
    //     "benchmark_price": "12.34",
    //     "baseline_price_comment": "Another benchmark",
    //     "update_frequency": "week",
    //     "update_frequency_option": "alternate",
    //     "lag_period": "0",
    //     "comparison_window": "0",
    //     "fuel_share_pcnt": "4",
    //     "minimum_change_from_baseline": "10",
    //     "min_adjustment": "10",
    //     "max_adjustment": "1",
    //     "carrier_verification_required": true,
    //     "internal_linking_code": "None",
    //     "dp_rounding": "2",
    //     "rate_verification_required": false,
    //     "policy_direction": "shipper",
    //     "contract_display_notes": "This is some more notes"
    // }

    let obj = {
      users_id: user.id,
      contract_display_name: policy.contract_display_name,
      internal_linking_code: "",
      policy_types_id: policy.policyType,
      fuel_price_indeces_id: policy.index_name,
      contract_start_date: policy.contract_start_date,
      contract_end_date: policy.contract_end_date,
      currencies_id: policy.currency_name,
      benchmark_price: policy.benchmark_price,
      baseline_price_comment: policy.baseline_price_comment,
      update_frequency: policy.update_frequency,
      update_frequency_option: policy.update_frequency_option,
      lag_period: policy.lag_period,
      comparison_window: policy.comparison_window,
      fuel_share_pcnt: policy.fuel_share_pcnt,
      minimum_change_from_baseline: policy.minimum_change_from_baseline,
      dp_rounding: policy.dp_rounding,
      reference_table_2nd_from_price: null,
      reference_table_last_from_price: null,
      reference_table_fuel_price_steps: null,
      reference_table_adjustment_per_step: null,
      reference_table_json_input: policy?.reference_table_json_input || null,
      carrier_verification_required: policy.carrier_verification_required,
      rate_verification_required: policy.rate_verification_required,
      rate_verification_start_date: null,
      supplier_linking_codes: null,
      placeholder_column_1: null,
      placeholder_column_2: null,
      creation_source: "",
      min_adjustment: policy.min_adjustment,
      max_adjustment: policy.max_adjustment,
      pending: policy.pending,
      distance_unit: "",
      test_policy_delete_at_date: null,
      created_at: null,
      updated_at: null,
      policy_direction: policy.policy_direction,
      contract_display_notes: policy.contract_display_notes,
      users_id_specific: null,
      day_policy_applies_from: policy.day_policy_applies_from || "monday",
      minimum_step_increment: policy.minimum_step_increment,
      date_based_on: policy.date_based_on,
      further_info_url: "",
      per_km_vehicle_type_consumption: null,
    };

    axios
      .post(
        "https://api.fastfloat.io/api:y81pgVS5/contracts_write_a_record",
        obj
      )
      .then(function (response) {
        console.log(response);
        toast.success("Created policy");
        navigate("/view-policy/" + response?.data?.id);
      })
      .catch(function (error) {
        toast.error(error?.message);
        console.log(error)
      });
  };

  console.log("currencies", currencies);
  if (
    !policyInputHelper?.data ||
    !fuelIndeces?.data ||
    !currencies?.data ||
    (id && !policy?.id)
  ) {
    return (
      <div>
        <p>
          <FontAwesomeIcon spin icon={faSpinner} className="text-md mr-2" />
          Loading
        </p>
      </div>
    );
  }
  return (
    <>

      <Joyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        disableBeacon={true}
        run={run}
        callback={(data) => {
          const { action, status, type } = data;

          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false);
          } else if (action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER) {
            // Mimic skip behavior on close
            setRun(false);
          }
        }}
        scrollOffset={100}
        styles={{
          options: {
            primaryColor: 'rgb(8 145 178)',
            textColor: '#004a14',
            textAlign: 'left',
            zIndex: 1000,
            width: 900,
          },
          beacon: {
            display: 'none',  // This ensures the beacon is not visible
            opacity: 0,       // This ensures the beacon does not flash
          }
        }}
      />
      <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">
        {getPolicyNameFromId(policy.policyType, l)}
      </h1>

      <button onClick={() => setRun(true)} class="absolute right-5 top-5 underline">Help</button>

      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 pb-20 sm:pb-20 xl:pb-20 relative">
        {policy?.id && (
          <Link
            className="absolute right-4 top-4 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-2 w-full sm:w-auto text-center"
            to={`/view-policy/${id}`}
          >
            View
          </Link>
        )}
        <Tooltip
          style={{ maxWidth: "300px", zIndex: 2 }}
          anchorSelect=".my-anchor-element"
        />

        {policyInputHelper?.data?.filter(a => !a.Advanced_only).sort((a, b) => a.display_order - b.display_order)?.map((f) => {
          if (f.display_for_policy_types_text.includes(policy.policyType)) {
            let dropDownOptions =
              f.List_of_standard_options_for_dropdowns.split(",");

            dropDownOptions = dropDownOptions.map((s) => s.trim());

            return renderHtml(f, dropDownOptions);
          } else {
            return <></>;
          }
        })}




        <div id="accordion-collapse" data-accordion="collapse" className="mt-8 mb-8 border border-t-0 border-l-0 border-r-0 border-b-1 border-gray-200">
          <h2 id="accordion-collapse-heading-1">
            <button onClick={() => setShowAdvanced(!showAdvanced)} type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
              <span>        <p className="text-xl leading-none font-bold text-gray-900 mb-1">
                Advanced options</p></span>
              <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
            <div class={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 ${showAdvanced ? '' : 'hidden'}`}>
              {policyInputHelper?.data?.filter(a => a.Advanced_only).sort((a, b) => a.display_order - b.display_order)?.map((f) => {
                if (f.display_for_policy_types_text.includes(policy.policyType)) {
                  let dropDownOptions =
                    f.List_of_standard_options_for_dropdowns.split(",");

                  dropDownOptions = dropDownOptions.map((s) => s.trim());

                  return renderHtml(f, dropDownOptions);
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        </div>


        {!id && (
          <button
            type="button"
            onClick={() => sendData()}
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-4 mb-4"
          >
            {l.lang('button_create_policy')}

          </button>
        )}
        {id && (
          <button
            type="button"
            onClick={() => updateData()}
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-4 mb-4"
          >
            {l.lang('button_update_policy')}
          </button>
        )}
      </div>
      {showBenchmarkModal && (
        <div
          className={
            "fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 flex items-center justify-center z-50 "
          }
        >
          <div className="w-1/2 p-8 bg-white rounded-lg shadow-lg h-3/4 overflow-x-scroll relative">
            <button onClick={() => {
              setShowBenchmarkModal(false);
            }} className="absolute top-0 right-0">
              <FontAwesomeIcon icon={faTimes} className="text-xl p-4" />
            </button>

            <h3 className="text-2xl leading-none font-bold text-gray-900 mb-4">
              {
                fuelIndeces?.data?.find(
                  (f) => f.Index_id === parseInt(policy?.index_name, 10)
                )?.Index_name
              }
            </h3>
            <h3 className="text-xl leading-none font-bold text-gray-900 mb-4">
              {l.lang('bm_table_title')}

            </h3>

            <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr class="text-center">
                  <th scope="col" class="px-4 py-4 w-[40%]">
                    {l.lang('bm_table_price_description')}
                  </th>
                  <th scope="col" class="px-4 py-4 w-[40%]">
                    {
                      currencies?.data?.find(
                        (c) => c.id === parseInt(policy.currency_name, 10)
                      )?.code
                    }
                    /litre
                  </th>
                  <th scope="col" class="px-4 py-4 w-[20%]"></th>
                </tr>
              </thead>
              <tbody>
                {fuelPriceDataBenchmark?.data?.map((p) => (
                  <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <td class="px-4 py-4">{p.period_label}</td>
                    <td class="px-4 py-4">{p.fuel_price_EUR.toFixed(2)}</td>
                    <td>
                      <button
                        class="ml-4 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
                        onClick={() => {
                          setShowBenchmarkModal(false);
                          setPolicy({
                            ...policy,
                            benchmark_price: p.fuel_price_EUR.toFixed(2),
                            baseline_price_comment:
                              "Avg price: " + p.period_label,
                          });
                        }}
                      >
                        {l.lang('button_select')}

                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {showPriceModal && (
        <div
          className={
            "fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 flex items-center justify-center z-50"
          }
        >
          <div className="w-3/4 p-8 bg-white rounded-lg shadow-lg h-3/4 overflow-x-scroll relative">
            <button onClick={() => {
              setShowPriceModal(false);
            }} className="absolute top-0 right-0">
              <FontAwesomeIcon icon={faTimes} className="text-xl p-4" />
            </button>
            <h3 className="text-2xl leading-none font-bold text-gray-900 mb-4">
              {l.lang('fi_table_title')}
            </h3>

            <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr class="text-center">
                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_index_name')}
                  </th>
                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_update_frequency')}
                  </th>
                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_last_update_date')}
                  </th>
                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_value_EUR_per_litre')}
                  </th>
                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_base_currency')}
                  </th>


                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_value_local_currency_per_litre')}
                  </th>

                  <th scope="col" class="px-4 py-4">
                    {l.lang('fi_table_data_source')}
                  </th>

                  <th scope="col" class="px-4 py-4"></th>
                </tr>
              </thead>
              <tbody>
                {fuelIndeces?.data?.map((p) => (
                  <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <td class="px-4 py-4">{p.index_name}</td>
                    <td class="px-4 py-4">
                      {p.update_frequency}
                    </td>
                    <td class="px-4 py-4">
                      {niceDate(p.last_update_date)}
                    </td>
                    <td class="px-4 py-4">
                      {p.value_EUR_per_litre}
                    </td>
                    <td class="px-4 py-4">{p.index_base_currency_code}</td>

                    <td class="px-4 py-4">
                      {p.value_local_currency_per_litre}

                    </td>



                    {/* <td class="px-4 py-4">{p.Fuel_type_select}</td> */}
                    <td class="px-4 py-4">
                      <a
                        className="underline"
                        target="_blank"
                        href={p.data_source_url} rel="noreferrer"
                      >
                        {l.lang('button_visit')}
                      </a>
                    </td>

                    <td>
                      <button
                        class="ml-4 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
                        onClick={() => {
                          setShowPriceModal(false);
                          setPolicy({ ...policy, index_name: p.Index_id });
                        }}
                      >
                        {l.lang('button_select')}

                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
