/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';
import "./App.css";
import Layout from "./components/Layout";
import { PageDashboard } from "./components/PageDashboard";
// import config from "./config/config";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import LanguageLoader from "./components/LanguageLoader.js";
import LayoutKN from "./components/LayoutKN";
import LayoutNoAuth from "./components/LayoutNoAuth";
import { PageApi } from "./components/PageApi";
import { PageCompare } from "./components/PageCompare";
import { PageContact } from "./components/PageContact";
import { PageCreatePolicy } from "./components/PageCreatePolicy";
import { PageCreatedPolicies } from "./components/PageCreatedPolicies";
import { PageFuelPriceData } from "./components/PageFuelPriceData";
import { PageKN } from "./components/PageKN";
import { PageLogin } from "./components/PageLogin";
import { PageConfirmEmail } from "./components/PageConfirmEmail";
import { PageLogout } from "./components/PageLogout";
import { PageMyAccount } from "./components/PageMyAccount";
import { PageSignUp } from "./components/PageSignUp";
import { PageSubscribedPolicies } from "./components/PageSubscribedPolicies";
import { PageViewPolicy } from "./components/PageViewPolicy";
import { PageViewPublicPolicy } from "./components/PageViewPublicPolicy";
import { PageManualCreatePolicyStep1 } from "./components/Policies/PageManualCreatePolicyStep1";
import { PageManualCreatePolicyStep2 } from "./components/Policies/PageManualCreatePolicyStep2";


function App() {

  const queryClient = new QueryClient()
  return (
    <div className="font-raleway">
      <QueryClientProvider client={queryClient}>
        <LanguageLoader>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <PageDashboard />
                </Layout>
              }
            />
            <Route
              path="/api"
              element={
                <LayoutNoAuth>
                  <PageApi />
                </LayoutNoAuth>
              }
            />
            <Route
              path="/view-policy"
              element={
                <Layout>
                  <PageViewPolicy />
                </Layout>
              }
            />
            <Route
              path="/public/:hash"
              element={
                  <PageViewPublicPolicy />
              }
            />
            <Route
              path="/view-policy/:id"
              element={
                <Layout>
                  <PageViewPolicy />
                </Layout>
              }
            />
            <Route
              path="/subscribed-policies"
              element={
                <Layout>
                  <PageSubscribedPolicies />
                </Layout>
              }
            />
            <Route
              path="/created-policies"
              element={
                <Layout>
                  <PageCreatedPolicies />
                </Layout>
              }
            />
            <Route
              path="/create-policy"
              element={
                <Layout>
                  <PageCreatePolicy />
                </Layout>
              }
            />
            <Route
              path="/policies/choose-type"
              element={
                <Layout>
                  <PageManualCreatePolicyStep1 />
                </Layout>
              }
            />
            <Route
              path="/policies/details"
              element={
                <Layout>
                  <PageManualCreatePolicyStep2 />
                </Layout>
              }
            />
            <Route
              path="/policies/edit/:id"
              element={
                <Layout>
                  <PageManualCreatePolicyStep2 />
                </Layout>
              }
            />
            <Route
              path="/fuel-price-data"
              element={
                <Layout>
                  <PageFuelPriceData />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <PageContact />
                </Layout>
              }
            />
            <Route
              path="/account"
              element={
                <Layout>
                  <PageMyAccount />
                </Layout>
              }
            />
              <Route
              path="/compare"
              element={
                <Layout>
                  <PageCompare />
                </Layout>
              }
            />
            <Route
              path="/kn-ece9d1-c26da9-0cb1e1"
              element={
                <LayoutKN>
                  <PageKN />
                </LayoutKN>
              }
            />

            <Route
              path="/login"
              element={
                <PageLogin />
              }
            />

            <Route
              path="/logout"
              element={
                <PageLogout />
              }
            />

            <Route
              path="/sign-up"
              element={
                <PageSignUp />
              }
            />

          <Route
              path="/confirm/:id"
              element={
                <PageConfirmEmail />
              }
            />
          </Routes>
        </BrowserRouter>
        </LanguageLoader>
      </QueryClientProvider>
      <Toaster />
    </div>
  )
}

export default App;
