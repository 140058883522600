import { Link } from "react-router-dom";

export const PageCreatePolicy = () => {


  
  return (
    <>
      <h1>Created policy</h1>
      <Link className="underline" to="/policies/choose-type">Create manual policy</Link>
    </>
  );
};

