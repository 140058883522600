import axios from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { tokenStringAtom } from "../state/state";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import Logo from "../images/fastfloat.png";

export const PageSignUp = () => {

    const [token] = useAtom(tokenStringAtom);
    const [formState, setFormState] = useState({ name: '', email: '', pass1: '', pass2: '', company: '' })
    const navigate = useNavigate()
    const [sentAuthEmail, setSentAuthEmail] = useState(false);


    const submitForm = (e) => {
        e.preventDefault();
        const hash = uuidv4();
        console.log(hash);


        axios.post('https://api.fastfloat.io/api:y81pgVS5/auth/signup', {
            name: formState.name,
            email: formState.email,
            password: formState.pass1,
            company_name: formState.company,
            confirmation_hash: hash,
        })
            .then(function (response) {
                if (response.status === 200 && response.data.authToken) {
                    // console.log(response);
                    sendAuthEmail({name: formState.name, email: formState.email, hash})
                    //setToken(response.data.authToken);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message);
            });

    }

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token])

    const updateField = (type, value) => {
        setFormState({ ...formState, [type]: value })

        console.log(formState);
    }

    const sendAuthEmail = ({name, email, hash}) => {

        const subject = "Please confirm your email address"
        const link = window.location.protocol + `//` + window.location.hostname + (window.location.port ? ":" + window.location.port : '') + `/confirm/${hash}`

        const message = 
`

Hi ${name},

Please confirm your email by visiting the following link:

${link}

Thanks,
FastFloat
`

        axios.post('https://api.fastfloat.io/api:y81pgVS5/confirmation-email', {
            to_email: email,
            subject,
            message,
          
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log(response);
                    setSentAuthEmail(true);

                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message);
            });

    }

    return (
        <main className="bg-gray-50">

            <div className="mx-auto min-h-screen flex flex-col justify-center items-center px-6 pt-8 pt:mt-0">
            <img src={Logo} className="w-64 mb-8" alt="FastFloat Logo" />

                {/* <!-- Card --> */}
                <div className="bg-white shadow rounded-lg md:mt-0 w-full sm:max-w-screen-sm xl:p-0 mb-16">
                    <div className="p-6 sm:p-8 lg:p-16 space-y-8">
                        {!sentAuthEmail && (
                            <>
                                <h2 className="text-2xl lg:text-3xl font-bold text-gray-900">
                                    Create a Free Account
                                </h2>
                                <form onSubmit={submitForm} className="mt-8 space-y-6" action="#">
                                    <div>
                                        <label htmlhtmlFor="company" className="text-sm font-medium text-gray-900 block mb-2">Company</label>
                                        <input value={formState.company} onChange={e => updateField('company', e.target.value)} type="text" name="company" id="company" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Acme" required />
                                    </div>
                                    <div>
                                        <label htmlhtmlFor="name" className="text-sm font-medium text-gray-900 block mb-2">Your name</label>
                                        <input value={formState.name} onChange={e => updateField('name', e.target.value)} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Joe Bloggs" required />
                                    </div>
                                    <div>
                                        <label htmlhtmlFor="email" className="text-sm font-medium text-gray-900 block mb-2">Your email</label>
                                        <input value={formState.email} onChange={e => updateField('email', e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="name@company.com" required />
                                    </div>
                                    <div>
                                        <label htmlhtmlFor="password" className="text-sm font-medium text-gray-900 block mb-2">Your password</label>
                                        <input value={formState.pass1} onChange={e => updateField('pass1', e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                    </div>
                                    <div>
                                        <label htmlhtmlFor="confirm-password" className="text-sm font-medium text-gray-900 block mb-2">Confirm password</label>
                                        <input value={formState.pass2} onChange={e => updateField('pass2', e.target.value)} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" aria-describedby="remember" name="remember" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" required />
                                        </div>
                                        <div className="text-sm ml-3">
                                            <label htmlhtmlFor="remember" className="font-medium text-gray-900">I accept the <Link to="/terms-and-conditions" className="text-teal-500 hover:underline">Terms and Conditions</Link></label>
                                        </div>
                                    </div>
                                    <button type="submit" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center">Create account</button>
                                    <div className="text-sm font-medium text-gray-500">
                                        Already have an account? <Link to="/login" className="text-teal-500 hover:underline">Login here</Link>
                                    </div>
                                </form>
                            </>)}
                        {sentAuthEmail && (
                            <>
                                <h2 className="text-2xl lg:text-3xl font-bold text-gray-900">
                                    Email confirmation
                                </h2>
                                <p>We have sent a confirmation email to {formState.email}. Please confirm your account via the link in your email.</p>
                            </>
                        )}


                    </div>
                </div>
            </div>
        </main>
    )

}