import { useState } from "react";
import EUFuelPrices from "./EUFuelPrices";
import RecentContracts from "./RecentContracts";
import Joyride from 'react-joyride';
import { Link } from "react-router-dom";

export const PageDashboard = () => {

  // const [steps, setSteps] = useState([
  //   {
  //     target: 'body',
  //     content: <div>
  //       <h1 class="text-2xl leading-none font-bold text-gray-900 mb-4">Get started</h1>
  //       <p class="mb-4">Watch our guide to using FastFloat</p>
  //       <iframe src="https://player.vimeo.com/video/347119375?h=1699409fe2&color=ef2200&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  //     </div>,
  //     placement: 'center',
  //     styles: {
  //       options: {
  //         width: '700px',
  //       }
  //     },
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '#policy-create',
  //     content: 'You can create a new policy here',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '#test',
  //     content: 'this is a test',
  //     disableBeacon: true,

  //   }
  // ]);




  return (
    <>

      {/* <Joyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        styles={{
          options: {
            primaryColor: 'rgb(8 145 178)',
            textColor: '#004a14',
            zIndex: 1000,
          }
        }}
      /> */}
      <div id="test" className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2 mb-4">

        <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">
          Get started</h1>

        <p>If you want to dive straight in, we've created some <Link className="underline" to="/subscribed-policies">example policies</Link> or <Link className="underline" to="/policies/choose-type">create a new policy</Link>.</p>

      </div>

      <RecentContracts />








    </>
  );
};

