import { useAtom } from "jotai";
import { usePolicyTypes } from "../../api/queries";
import { newPolicyObjAtom } from "../../state/state";
import { useNavigate } from "react-router-dom";
import useLang from "../../components/useLang";
import { getPolicyDescriptionFromId, getPolicyNameFromId } from "../../helpers/generalFunctions.ts";

export const PageManualCreatePolicyStep1 = () => {

  const policyTypes = usePolicyTypes();
  const navigate = useNavigate()
  const l = useLang();

  console.log(policyTypes)

  const [policy, setPolicy] = useAtom(newPolicyObjAtom)


  const nextStep = (policyType, policyTypeName) => {
    setPolicy({ ...policy, policyType, policyTypeName })
    navigate('/policies/details')
  }

 


  return (
    <>
      <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">{l.lang('create_policy_step1_title')}</h1>
      <div class="w-full mb-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 gap-4">

        {policyTypes?.data?.map(p => (

          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 pb-20 sm:pb-20 xl:pb-20 relative">

            <div className="flex  items-center">
              <div className="">
                <p className="text-xl leading-none font-bold text-gray-900 mb-4">{getPolicyNameFromId(p.id, l)}</p>
                <p className="text-sm font-normal text-gray-500 mb-2">{getPolicyDescriptionFromId(p.id, l)}</p>
                <p className="absolute bottom-8 mt-4">
                  <button onClick={() => { nextStep(p.id, p.type_name) }} type="button" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-3 py-2 w-full sm:w-auto text-center">{l.lang('button_select')}</button>
                </p>
              </div>

            </div>
          </div>

        ))}

      </div>

    </>
  );
};

