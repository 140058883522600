import "react-toastify/dist/ReactToastify.css";
import { useTranslations } from "../api/queries";



const LanguageLoader = (props) => {

    const language = useTranslations();
    console.log(language);

    const { children } = props;

    if (!language.isSuccess) {
        return <></>
    }

    return (
        <>
            {children}
        </>


    );
};

export default LanguageLoader;
