import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect } from "react";

const PolicyGraphType1or2 = ({ graphData, id, frequency, xLabel, xLabel2, vehicleType, refPrice }) => {
    useEffect(() => {
        const root = am5.Root.new("chartdiv2");
        const myTheme = am5.Theme.new(root);
        const currencyCode = graphData?.[0]?.currency_used_for_policy || ''
        myTheme.rule("Label").setAll({
            // fill: am5.color(0xFF0000),
            fontSize: "14"
        });

        let convertedData = graphData.map(d => {
            d.per = d.adjustment_pcnt * 100;
            d.refPrice = refPrice;

            if (vehicleType != null) {
                d.per = d.vehicle_type_output.find(v => v.value.id === vehicleType).value.adjustment_amount * 100
            }

            if (frequency === 'quarter') {
                d.date = new Date(d.period1).getTime();


            } else {
                d.date = new Date(d.period_floater).getTime();

            }



            if (d.actual_vs_forecast_vs_projection !== 'actual') {
                d.columnSettings = {
                    strokeWidth: 1,
                    strokeDasharray: [5],
                    fillOpacity: 0.2
                };
                d.strokeSettings = {
                    // stroke: chart.get("colors").getIndex(1),
                    strokeWidth: 3,
                    strokeDasharray: [5, 5]
                }
            }

            if (d.current_period_boolean) {
                if (!d?.columnSettings) {
                    d.columnSettings = {}
                }
                d.columnSettings.fill = am5.color(0x56B6F2);
            }

            return d;
        })

        console.log('converted', convertedData)

        root.setThemes([myTheme]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: false,
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout
            })
        );



        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        // chart.set(
        //     "scrollbarX",
        //     am5.Scrollbar.new(root, {
        //         orientation: "horizontal"
        //     })
        // );



        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        // let xRenderer = am5xy.AxisRendererX.new(root, {});
        // let xAxis = chart.xAxes.push(
        //     am5xy.CategoryAxis.new(root, {
        //         categoryField: "period_floater",
        //         renderer: xRenderer,
        //         tooltip: am5.Tooltip.new(root, {})
        //     })
        // );

        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: frequency === 'week' ? 'week' : 'month', count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 50

                })
            })
        );

        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        //   }));


        //   xAxis.get("dateFormats")["month"] = "MM";
        // xAxis.get("periodChangeDateFormats")["day"] = "MMM";

        // xRenderer.grid.template.setAll({
        //     location: 1
        // })

        xAxis.data.setAll(graphData);

        xAxis.children.push(am5.Label.new(root, {
            text: xLabel,
            fontSize: 12,
            rotation: 0,
            fontWeight: "400",
            textAlign: "center",
            x: am5.percent(50),
            y: am5.percent(100),
            centerX: am5.percent(50),
            paddingTop: 0,
            paddingBottom: 0
        }));


        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0.1,

                })
            })
        );

        yAxis.children.push(am5.Label.new(root, {
            text: "Average Fuel Price for Period  (" + currencyCode + ")",
            fontSize: 12,
            rotation: 270,
            fontWeight: "400",
            textAlign: "center",
            x: -15,
            y: am5.percent(50),
            centerY: am5.percent(50),
            paddingTop: 0,
            paddingBottom: 0
        }));



        let yAxis2 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1, opposite: true })

            })
        );

        yAxis2.children.push(am5.Label.new(root, {
            text: xLabel2,
            fontSize: 12,
            rotation: 270,
            fontWeight: "400",
            textAlign: "center",
            x: 35,
            y: am5.percent(50),
            centerY: am5.percent(50),
            paddingTop: 0,
            paddingBottom: 0
        }));

        yAxis.get("renderer").grid.template.set("forceHidden", true);


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        let series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Percent",
                xAxis: xAxis,
                yAxis: yAxis2,
                valueYField: "per",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: xLabel2 === 'Fuel Adjustment %' ?  "Fuel Adjustment {valueY}%" : xLabel2 + " (" + currencyCode + ") {valueY}"
                })
            })
        );

        series1.columns.template.setAll({
            tooltipY: am5.percent(10),
            templateField: "columnSettings"
        });

        series1.data.setAll(convertedData);


        let series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Fuel price",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "fuel_price_for_required_period",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "Fuel price (" + currencyCode + ") {valueY}",
                })
            })
        );

        series2.strokes.template.setAll({
            strokeWidth: 3,
            templateField: "strokeSettings"
        });


        series2.data.setAll(convertedData);

        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    strokeWidth: 1,
                    stroke: series2.get("stroke"),
                    radius: 4,
                    fill: am5.color(0xffffff)
                })
            });
        });


        let series3 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Reference price",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "refPrice",
                valueXField: "date",
                stroke: am5.color(0x095256),
                fill: am5.color(0x095256),
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "Reference price {valueY}",
                })
            })
        );

        series3.strokes.template.setAll({
            strokeWidth: 2,
            templateField: "strokeSettings",
            strokeDasharray: [5,5]
        });


        series3.data.setAll(convertedData);

        chart.set("cursor", am5xy.XYCursor.new(root, {}));



        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        // let legend = chart.children.push(
        //     am5.Legend.new(root, {
        //         centerX: am5.p50,
        //         x: am5.p50
        //     })
        // );
        // legend.data.setAll(chart.series.values);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
        series1.set("fill", am5.color(0x82A563));
        series2.set("stroke", am5.color(0x24406E));

        series1.appear();
        series2.appear();


        return () => {
            root.dispose();
        }

    }, [id, vehicleType, graphData])

    return (
        <div id="chartdiv2" style={{ width: "100%", height: "350px" }}></div>
    )

}

export default PolicyGraphType1or2;