import axios from "axios";
import { useAtom } from "jotai";
import { useQuery } from "react-query";

const url = 'https://api.fastfloat.io/api:y81pgVS5/'
const urlFunctionStack = 'https://api.fastfloat.io/api:7SH7zSAW/';

export const useGetFuelPriceIndeces = () => useQuery('fuelPriceIndeces', () => apiCall({ endpoint: 'get_fuel_price_indeces_list_summarised_with_latest_price',urlOverride: urlFunctionStack  }), {
    staleTime: 1000 * 60 * 5,
});

export const useTranslations = () => useQuery('language', () => apiCall({endpoint: 'language'}), {
    staleTime: 1000 * 60 * 30,
});


export const useUserContracts = () => useQuery('userContracts', () => apiCall({ endpoint: 'contracts' }), {
    staleTime: 1000 * 60,
});

export const useContractsCreatedByUser = () => useQuery('contractsCreatedByUser', () => apiCall({ endpoint: 'contracts_created_by_user', }), {
    staleTime: 1000 * 60,
});

export const useContract = ({ id, userId }) => useQuery(['contract', { id: id }], () => apiCall({ endpoint: `contracts/${id}?show_projections=true&historical_months=-13&user_id=${userId}`, urlOverride: urlFunctionStack }), {
    enabled: !!id && !!userId,
    staleTime: 1000 * 60,
    cacheTime: 0,
});

export const useContractNoUser = ({ id }) => useQuery(['contract', { id: id }], () => apiCall({ endpoint: `contracts-clone/${id}?show_projections=true&historical_months=-13&user_id=1321651547`, urlOverride: urlFunctionStack }), {
    enabled: !!id,
    staleTime: 1000 * 60,
    cacheTime: 0,
});

export const useContractByHash = ({ hash }) => useQuery(['contractByHash', { hash: hash }], () => apiCall({ endpoint: `contracts-by-hash/${hash}`, urlOverride: urlFunctionStack }), {
    enabled: !!hash,
    staleTime: 1000 * 60,
    cacheTime: 0,
});

export const useBasicContract = ({ id, userId }) => useQuery(['basicContract', { id: id }], () => apiCall({ endpoint: `contracts/${id}?&user_id=${userId}`}), {
    enabled: !!id && !!userId,
    staleTime: 1000 * 60,
    cacheTime: 0,

});

export const useFuelPriceDataForBenchmark = ({ id }) => useQuery(['fuelPriceDataForBenchmark', { id: id }], () => apiCall({ endpoint: `fuel_price_data_for_benchmark?fuel_price_index_id=${id}`, urlOverride: urlFunctionStack }), {
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
});

export const useContractInputHelper = ({ typeId }) => useQuery(['contractInputHelper', { typeId: typeId }], () => apiCall({ endpoint: `contract_input_helper?policy_type_id=${typeId}` }), {
    enabled: !!typeId,
    staleTime: 1000 * 60,
});

export const usePolicyTypes = () => useQuery('policyTypes', () => apiCall({ endpoint: 'policy_types' }), {
    staleTime: 1000 * 60 * 60,
});

export const useCurrencies = () => useQuery('currencies', () => apiCall({ endpoint: 'currencies' }), {
    staleTime: 1000 * 60 * 60,
});

export const useFuelPriceIndecesListSummary = () => useQuery(['useFuelPriceIndecesListSummary', {}], () => apiCall({ endpoint: `get_fuel_price_indeces_list_summarised`, urlOverride: urlFunctionStack }), {
    staleTime: 1000 * 60,
});

const paramString = (idArr) => {
    let string = '';
    idArr.forEach((id) => {
        string += `&contract_ids_list[]=${id}`
    })
    return string;
}

export const useContractComparison = ({ idArray, months }) => useQuery(['useContractComparison', { idArray: idArray, months: months }], () => apiCall({ endpoint: `contracts/NA/list_for_multiple_dates?show_projections=false&historical_months=-${months}&current_period_only=false${paramString(idArray)}`, urlOverride: urlFunctionStack }), {
    enabled: idArray.length > 0 && !!months,
    staleTime: 1000 * 60 * 5,
});

export const apiCall = ({
    endpoint, method, data, urlOverride = null,
}) => {

    return axios({
        method,
        url: (urlOverride ? urlOverride : url) + endpoint,
        data,
    }).then(function (response) {
        if (response.status === 200) {
            return (response.data);
        } else {
            throw new Error('Not 200');


        }
    })
        .catch(function (error) {
            throw new Error(error);


        });



};