import { DateTime } from "luxon";

export const nicePeriod = (dateString: string, period: string): string => {

    const dateTime = DateTime.fromISO(dateString)


    if (period === 'month') {
        // Jan 2023
        return dateTime.toFormat('LLL yyyy')

    }
    if (period === 'week') {
        // Week ending 12 Jan 2023
        return `week ending ${dateTime.toFormat('dd LLL yyyy')}`

    }
    if (period === 'quarter') {
        // Week ending 12 Jan 2023
        return `${dateTime.toFormat('LLL yyyy')}`
    }
    return '';

}

export const nicePeriodTable = (dateString: string, period: string): string => {

    const dateTime = DateTime.fromISO(dateString)


    if (period === 'month') {
        // Jan 2023
        return dateTime.toFormat('LLL yyyy')

    }
    if (period === 'week') {
        // Week ending 12 Jan 2023
        return `${dateTime.toFormat('dd LLL yyyy')}`

    }
    if (period === 'quarter') {
        // Week ending 12 Jan 2023
        return `${dateTime.toFormat('LLL yyyy')}`
    }
    return '';

}

export const niceDate = (dateString: string): string => {

    const dateTime = DateTime.fromISO(dateString)
    return dateTime.toFormat('dd LLL yyyy');


}

export const niceMonthAndYear = (dateString: string): string => {

    const dateTime = DateTime.fromISO(dateString)
    return dateTime.toFormat('LLL yyyy');


}

export const niceXLabel = (period): string => {

    if (period === 'month') {
        // Jan 2023
        return 'Shipment Month'

    }
    if (period === 'week') {
        // Week ending 12 Jan 2023
        return 'Shipment Week Ending'

    }
    if (period === 'quarter') {
        // Week ending 12 Jan 2023
        return 'Shipment Month'
    }
    return '';


}

export const getPolicyNameFromId = (id, l) => {
    switch (id) {
        case 1:
            return l.lang('create_policy_option_benchmark_title')
        case 2:
            return l.lang('create_policy_option_table_title')
        case 3:
            return l.lang('create_policy_option_perkmlitre_title')
        case 4:
            return l.lang('create_policy_option_perkmkm_title')

        default:
            return 'Unknown';
    }
}

export const getPolicyDescriptionFromId = (id, l) => {

    switch (id) {
        case 1:
            return l.lang('create_policy_option_benchmark_detail')
        case 2:
            return l.lang('create_policy_option_table_detail')
        case 3:
            return l.lang('create_policy_option_perkmlitre_detail')
        case 4:
            return l.lang('create_policy_option_perkmkm_detail')

        default:
            return 'Unknown';
    }
}