import {
  faChartLine,
  faDashboard,
  faEnvelopeOpen,
  faFileCirclePlus,
  faFolder,
  faSignOut,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useAtom } from "jotai";
import { useEffect } from "react";
import InitialsAvatar from 'react-initials-avatar';
import { Link, NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../images/fastfloat.png";
import { browserLangString, fuelPriceIndecesObjAtom, tokenStringAtom, userObjAtom } from "../state/state";
import useLang from "./useLang";
import { useTracking } from "../hooks/useTracking.ts";



const Layout = (props) => {
  useTracking('G-YKQDT3PLCX')

  const [browserLang, setBrowserLang] = useAtom(browserLangString);
  const [token, setToken] = useAtom(tokenStringAtom)
  const [user, setUser] = useAtom(userObjAtom);
  const [, setFuelPriceIndeces] = useAtom(fuelPriceIndecesObjAtom);
  const l = useLang();

  const navigate = useNavigate()

  const testPolicies = [
    {
      name: "Type 1 - Weekly",
      id: 205
    },
    {
      name: "Type 1 - Monthly",
      id: 210
    },
    {
      name: "Type 1 - Quarterly",
      id: 213
    },

    {
      name: "Type 2 - Weekly",
      id: 215
    },
    {
      name: "Type 2 - Monthly",
      id: 217
    },
    {
      name: "Type 2 - Quarterly",
      id: 218
    },

    {
      name: "Type 3 - Weekly",
      id: 221
    },
    {
      name: "Type 3 - Monthly",
      id: 224
    },
    {
      name: "Type 3 - Quarterly",
      id: 225
    },
    {
      name: "Type 4 - Weekly",
      id: 228
    },
    {
      name: "Type 4 - Monthly",
      id: 230
    },
    {
      name: "Type 4 - Quarterly",
      id: 231
    },

    {
      name: "Type 2b custom - Weekly",
      id: 219
    },
    {
      name: "Type 2b custom - Monthly",
      id: 220
    },
    {
      name: "Type 2b custom - Quarterly",
      id: 222
    },
  ]

  useEffect(() => {
    if (user?.name) {

      if (user.name.includes('Admin')) {
        setToken('');
        navigate('/login');
        return;

      }

      return;
    }

    
    if (!token || token.length === 0) {
      console.log('No token');
      navigate('/login');
      return;
    }


    console.log('token', token);

    // get the user details
    axios.get('https://api.fastfloat.io/api:y81pgVS5/auth/me', { headers: { "Authorization": `Bearer ${token}` } })
      .then(function (response) {
        if (response.status === 200) {
          setUser(response.data);
          console.log(response.data);
        } else {
          console.log('API not 200', response.status);
          setToken('');
          navigate('/login');
        }
      })
      .catch(function (error) {
        console.log(error);
        setToken('');
        navigate('/login');
      });


  }, [token, user])

  // effect for setting axios security token
  useEffect(() => {
    if (!token) {
      axios.defaults.headers.common['Authorization'] = null;
    } else {
      console.log('SETTING HEADER');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

  }, [token])


  const { children } = props;

  return (




    <>


      <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
        <div className="px-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center justify-start">
              <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">
                <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                <svg id="toggleSidebarMobileClose" className="w-6 h-6 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
              <Link to="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                <img src={Logo} className="w-52 mr-2" alt="FastFloat Logo" />
                {/* <span className="self-center whitespace-nowrap">FastFloat</span> */}
              </Link>

            </div>
            <div className="flex items-center">
              <p className="mr-3">{user?.name} - {user?.company_name}</p>
              <div className="flex-shrink-0">
                <InitialsAvatar name={user?.name || ""} />

              </div>
              <button className="underline ml-4" onClick={() => { setBrowserLang(browserLang === 'en' ? 'de' : 'en') }}>{browserLang === 'en' ? 'Deutsch' : 'English'}</button>



            </div>
          </div>
        </div>
      </nav>
      <div className="flex overflow-hidden bg-white pt-16">

        <aside id="sidebar" className="fixed hidden z-20 h-full top-0 left-0 pt-16 flex lg:flex flex-shrink-0 flex-col w-64 transition-width duration-75" aria-label="Sidebar">
          <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-ff-blue pt-0">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex-1 px-3 bg-ff-blue space-y-1">
                <ul className="space-y-3 pb-2">
                  <li>
                    <NavLink to="/" className={({ isActive }) => `text-base ${isActive ? 'text-white' : 'text-ff-grey'} font-normal rounded-lg flex items-center p-2 hover:text-white group`}>
                      <span className="w-10"><FontAwesomeIcon icon={faDashboard} className="text-xl" /></span>
                      <span>{l.lang('menu_dashboard')}</span>

                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/view-policy" className={({ isActive }) => `text-base ${isActive ? 'text-white' : 'text-ff-grey'} font-normal rounded-lg flex items-center p-2 hover:text-white group`}>
                      <span className="w-10"><FontAwesomeIcon icon={faFileContract} className="text-xl" /></span>
                      <span >View policy</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <Link to="/subscribed-policies" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faFolder} className="text-xl" /></span>
                      <span>{l.lang('menu_my_policies')}</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/compare" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faFolder} className="text-xl" /></span>
                      <span>Compare</span>
                    </Link>
                  </li> */}
                  {/* <ul>
                    {testPolicies.map((p) => (
                      <li><Link className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group" to={`/view-policy/${p.id}`}>{p.name}</Link></li>
                    ))}
                  </ul> */}
                  {/* <li>
                    <Link to="/created-policies" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faFolder} className="text-xl" /></span>
                      <span >My created policies</span>
                    </Link>
                  </li> */}
                  <li id="policy-create">
                    <Link to="/policies/choose-type" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faFileCirclePlus} className="text-xl" /></span>
                      <span>{l.lang('menu_create_policy')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/fuel-price-data" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faChartLine} className="text-xl" /></span>
                      <span>{l.lang('menu_fuel_price_data')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faEnvelopeOpen} className="text-xl" /></span>
                      <span>{l.lang('menu_contact_us')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faUser} className="text-xl" /></span>
                      <span>{l.lang('menu_my_account')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout" className="text-base text-ff-grey font-normal rounded-lg flex items-center p-2 hover:text-white group">
                      <span className="w-10"><FontAwesomeIcon icon={faSignOut} className="text-xl" /></span>
                      <span>{l.lang('menu_logout')}</span>
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </aside>

        <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>

        <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
          <main>
            <div className="pt-6 px-4">
              {children}
            </div>
          </main>
          <footer className="bg-white md:flex md:items-center md:justify-between shadow rounded-lg p-4 md:p-6 xl:p-8 my-6 mx-4">
            <ul className="flex items-center flex-wrap mb-6 md:mb-0">
              <li><a href="#" className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">{l.lang('footer_terms_and_conditions')}</a></li>
              <li><a href="#" className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">{l.lang('footer_privacy_policy')}</a></li>
              <li><a href="#" className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">{l.lang('footer_licensing')}</a></li>
              <li><a href="#" className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">{l.lang('footer_cookie_policy')}</a></li>
              <li><a href="#" className="text-sm font-normal text-gray-500 hover:underline">{l.lang('footer_contact')}</a></li>
            </ul>
            <div className="flex sm:justify-center space-x-6">
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          </footer>
          <p className="text-center text-sm text-gray-500 my-10">
            &copy; 2022 <a href="#" className="hover:underline" target="_blank">FastFloat</a>
          </p>

        </div>

      </div>

    </>


  );
};

export default Layout;
