const ReferenceTable = ({summary}) => {
    const data = summary?.reference_table;

    if (!data) {
        return <></>
    }
    return (
        <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">

                <tr class="text-center">
                    <th scope="col" class="px-4 py-4 w-[33%]">From price</th>
                    <th scope="col" class="px-4 py-4 w-[33%]">To price</th>
                    <th scope="col" class="px-4 py-4 w-[33%]">Adjustment %</th>

                </tr>
            </thead>
            <tbody>

                {data.map(g => (
                    <tr class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">{g.from_price}</td>
                        <td class="px-4 py-4">{g.to_price}</td>
                        <td class="px-4 py-4">{g.adjustment_pcnt}</td>

                    </tr>

                ))}
            </tbody>
        </table>
    )
}

export default ReferenceTable;