import axios from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { tokenStringAtom } from "../state/state";
import { useNavigate } from 'react-router-dom';
import Logo from "../images/fastfloat.png";
import useLang from "./useLang";

export const PageLogin = () => {
    const [token, setToken] = useAtom(tokenStringAtom);
    const [formState, setFormState] = useState({ email: '', password: '' })
    const navigate = useNavigate()
    const l = useLang();
    const submitForm = (e) => {
        e.preventDefault();
        axios.post('https://api.fastfloat.io/api:y81pgVS5/auth/login', {
            email: formState.email,
            password: formState.password
        })
            .then(function (response) {
                console.log(response);

                if (response.status === 200 && response.data.authToken) {
                    setToken(response.data.authToken);
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const updateField = (type, value) => {
        setFormState({ ...formState, [type]: value })

        console.log(formState);
    }

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token])

    return (
        <main className="bg-gray-50">
            <div className="mx-auto min-h-screen flex flex-col justify-center items-center px-6 pt-8 pt:mt-0">
                <img src={Logo} className="w-64 mb-8" alt="FastFloat Logo" />

                {/* <!-- Card --> */}
                <div className="bg-white shadow rounded-lg md:mt-0 w-full sm:max-w-screen-sm xl:p-0">
                    <div className="p-6 sm:p-8 lg:p-16 space-y-8">
                        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900">
                            {l.lang('signin_title')}
                        </h2>
                        <form onSubmit={submitForm} className="mt-8 space-y-6">
                            <div>
                                <label htmlFor="email" className="text-sm font-medium text-gray-900 block mb-2">{l.lang('signin_your_email')}</label>
                                <input value={formState.email} onChange={e => updateField('email', e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="text-sm font-medium text-gray-900 block mb-2">{l.lang('signin_your_password')}</label>
                                <input value={formState.password} onChange={e => updateField('password', e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="flex items-start">

                                <Link to="/forgotten-password" className="text-sm text-teal-500 hover:underline ml-auto">{l.lang('signin_lost_password')}</Link>
                            </div>
                            <button type="submit" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center">{l.lang('signin_login_to_your_account')}</button>
                            <div className="text-sm font-medium text-gray-500">
                            {l.lang('signin_not_registered')} <Link to="/sign-up" className="text-teal-500 hover:underline">{l.lang('signin_create_account')}</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </main>
    )

}