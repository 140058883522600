import { niceDate } from "../helpers/generalFunctions.ts";

const ComparisonTable = ({ data }) => {

    if (!data) {
        return <></>
    }
    return (
        <table class="w-full text-xs text-center text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">

                <tr class="text-center">
                    <th scope="col" class="px-4 py-4 w-[5%]">ID</th>
                    <th scope="col" class="px-4 py-4 ">Name</th>
                    <th scope="col" class="px-4 py-4 ">Period</th>
                    <th scope="col" class="px-4 py-4 ">Price</th>
                    <th scope="col" class="px-4 py-4 ">Adjustment %</th>
                    <th scope="col" class="px-4 py-4 ">Actual/Projection</th>
                    <th scope="col" class="px-4 py-4 ">Internal Code</th>

                </tr>
            </thead>
            <tbody>
                {data.map((d, i) => (
                    <tr key={`row${i}`} class="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-4 py-4">{d.contract_id}</td>
                        <td class="px-4 py-4">{d.contract_display_name}</td>
                        <td class="px-4 py-4">{d.period_floater}</td>
                        <td class="px-4 py-4">{d.fuel_price_for_required_period}</td>
                        <td class="px-4 py-4">{d.adjustment_pcnt}</td>
                        <td class="px-4 py-4">{d.actual_vs_forecast_vs_projection}</td>
                        <td class="px-4 py-4">{d.internal_linking_code}</td>

                    </tr>

                ))}




            </tbody>
        </table>
    )
}

export default ComparisonTable;