import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGetFuelPriceIndeces } from "../api/queries";
import { toast } from "react-hot-toast";
import { lang } from "../helpers/language";
import { useAtom } from "jotai";
import { browserLangString } from "../state/state";

export const PageApi = () => {

    const fuelPriceIndecesQuery = useGetFuelPriceIndeces();
    const [index, setIndex] = useState(null);
    const [updateFrequency, setUpdateFrequency] = useState("month")
    const [priceLag, setPriceLag] = useState("1")
    const [comparisonWindow, setComparisonWindow] = useState("1")
    const [sharePercentage, setSharePercentage] = useState(null)
    const [date, setDate] = useState(null)
    const [refPrice, setRefPrice] = useState(null)
    const [indexTunnel, setIndexTunnel] = useState(0)
    const [resultObject, setResultObject] = useState({})
    const [browserLang, setBrowserLang] = useAtom(browserLangString);

    console.log(fuelPriceIndecesQuery);

    const payload = {
        fuel_index_id: index,
        update_frequency: updateFrequency,
        Price_Lag_Duration: priceLag,
        comparison_window: comparisonWindow,
        Fuel_Cost_Share_Percentage: sharePercentage,
        Index_Tunnel: indexTunnel,
        Fuel_Index_Reference_Price: refPrice,
        specific_date_input: date ? new Date(date).toISOString().split('T')[0] : null,
    }

    const sendData = () => {

        let missingFields = [];
        if (date === null) {
            missingFields.push('specific_date_input');
        }
        if (refPrice === null) {
            missingFields.push('Fuel_Index_Reference_Price');
        }
        if (sharePercentage === null) {
            missingFields.push('Fuel_Cost_Share_Percentage');
        }

        if (missingFields.length > 0) {
            toast.error(`${lang('missing_fields', browserLang)}:\n${missingFields.join("\n")}`);
            return;
        }


        setResultObject('Fetching result from API...');
        axios.post('https://api.fastfloat.io/api:bSMud0Vs/Adjustment_calc_for_specific_date_value_output_only_BENCHMARK_PRICE_ONLY', payload)
            .then(function (response) {
                setResultObject(response.data);
            })
            .catch(function (error) {
                setResultObject({
                    code: error?.code,
                    message: error?.message
                })
            });


    }

    useEffect(() => {
        if (index === null) {
            return;
        }
        const weeklyAvailable = fuelPriceIndecesQuery?.data[index].weekly_available;

        if (!weeklyAvailable && updateFrequency === 'week') {
            setUpdateFrequency('month');
        }

    }, [index])


    return (
        <div className="font-raleway bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-8">FastFloat API</h1>

            <h2 id="adjustment" className="text-xl font-bold test-gray-900">{lang('adjustment_calculation_api_name', browserLang)}</h2>
            <p className="mt-4">{lang('adjustment_calculation_api_description', browserLang)}</p>

            <div className="bg-gray-100 rounded-lg p-4 mt-6 py-4">
                <h3 className="font-bold mb-2">fuel_index_id <span className="font-normal">({lang('integer', browserLang)})</span> <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <select onChange={(e) => setIndex(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4" name="index" id="index">
                    <option disabled selected>{lang('select_index', browserLang)}</option>
                    {fuelPriceIndecesQuery?.data?.map(p => (
                        <option value={p.id}>{p.index_name}</option>

                    ))}
                </select>
                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-0">update_frequency <span className="font-normal">({lang('string', browserLang)})</span>  <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p>{lang('adjustment_calculation_api_update_frequency', browserLang)}</p>

                <select onChange={(e) => setUpdateFrequency(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4" name="freq" id="freq">
                    <option disabled selected>{lang('adjustment_calculation_api_update_frequency_select', browserLang)}</option>
                    <option value="month">{lang('month', browserLang)}</option>
                    <option value="quarter">{lang('quarter', browserLang)}</option>
                    {index !== null && fuelPriceIndecesQuery?.data[index].weekly_available &&
                        <option value="week">{lang('week', browserLang)}</option>
                    }

                </select>

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-6">Price_Lag_Duration <span className="font-normal">({lang('integer', browserLang)})</span> <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p>{lang('adjustment_calculation_api_price_lag_period', browserLang)}</p>

                <select onChange={(e) => setPriceLag(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4" name="lag" id="lag">
                    <option disabled selected>{lang('adjustment_calculation_api_price_lag_period_select', browserLang)}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>

                </select>

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-4">comparison_window <span className="font-normal">({lang('integer', browserLang)})</span>  <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p>{lang('adjustment_calculation_api_comparison_window', browserLang)}</p>

                <select onChange={(e) => setComparisonWindow(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-4" name="comparison" id="comparison">
                    <option disabled selected>{lang('adjustment_calculation_api_comparison_window_select', browserLang)}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>

                </select>

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-4">Fuel_Cost_Share_Percentage <span className="font-normal">({lang('number', browserLang)})</span>  <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p className="mb-2">{lang('adjustment_calculation_api_share_pecentage', browserLang)}</p>
                <input min={0.05} max={0.5} step={0.01} placeholder={lang('adjustment_calculation_api_share_pecentage_placeholder', browserLang)} type="number" onChange={(e) => setSharePercentage(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2" name="share" id="share" />

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-4">Index_Tunnel <span className="font-normal">({lang('number', browserLang)})</span></h3>
                <p>{lang('adjustment_calculation_api_index_tunnel', browserLang)}</p>
                <input type="number" onChange={(e) => setIndexTunnel(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2" name="tunnel" id="tunnel" />

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-4">Fuel_Index_Reference_Price <span className="font-normal">({lang('number', browserLang)})</span>  <span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p>{lang('adjustment_calculation_api_fuel_index_reference_price', browserLang)}</p>
                <input type="number" placeholder={lang('adjustment_calculation_api_fuel_index_reference_price_placeholder', browserLang)} onChange={(e) => setRefPrice(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2" name="ref" id="ref" />

                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                <h3 className="font-bold mb-2 mt-4">specific_date_input  <span className="font-normal">({lang('date', browserLang)})</span><span className="text-sm">* {lang('required', browserLang)}</span></h3>
                <p>{lang('specific_date_input', browserLang)}</p>
                <input type="date" onChange={(e) => setDate(e.target.value)} className="lg:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2 mb-4" name="date" id="date" />


            </div>

            <div className="bg-gray-100 rounded-lg p-4 mt-4">
                <h2 className="font-bold text-xl mb-4">{lang('test_endpoint', browserLang)}</h2>

                <p className="mb-2">{lang('payload', browserLang)}:</p>
                <pre className="bg-white p-4 rounded">
                    <code>{JSON.stringify(payload, null, 4)}</code>
                </pre>

                <button type="button" onClick={() => sendData()} className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center mt-4 mb-4">{lang('submit', browserLang)}</button>

                <p className="mb-2">{lang('result', browserLang)}:</p>
                <pre className="bg-white p-4 rounded">
                    <code>{JSON.stringify(resultObject, null, 4)}</code>
                </pre>
            </div>
        </div>
    )

}