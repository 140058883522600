import { useAtom } from "jotai";
import { useEffect } from "react";
import { tokenStringAtom, userObjAtom } from "../state/state";
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query'

export const PageLogout = () => {
    const [,setToken] = useAtom(tokenStringAtom);
    const [,setUser] = useAtom(userObjAtom);

    const navigate = useNavigate()
    const queryClient = useQueryClient()


    useEffect(() => {
        setToken('');
        setUser({});
        queryClient.clear();
        navigate('/login');

    }, [])

    return (
        <main className="bg-gray-50">
            <div className="mx-auto md:h-screen flex flex-col justify-center items-center px-6 pt-8 pt:mt-0">
                <a href="http://localhost:1313/windster/" className="text-2xl font-semibold flex justify-center items-center mb-8 lg:mb-10">
                    <span className="self-center text-2xl font-bold whitespace-nowrap">FastFloat</span>
                </a>
                {/* <!-- Card --> */}
                <div className="bg-white shadow rounded-lg md:mt-0 w-full sm:max-w-screen-sm xl:p-0">
                    <div className="p-6 sm:p-8 lg:p-16 space-y-8">
                        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900">
                            Logging you out.
                        </h2>

                    </div>
                </div>
            </div>

        </main>
    )

}