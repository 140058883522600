import React, { useEffect } from "react";

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { niceDate } from "../helpers/generalFunctions.ts";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { comparePolicyArrayAtom } from "../state/state.js";



declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}



const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const SearchTable = ({
  data,
  policyTypes,
  indeces,
}: {
  data: any;
  policyTypes: any;
  indeces: any;
}) => {
  const [compareArray, setCompareArray] = useAtom(comparePolicyArrayAtom);
  const cArrayRef = React.useRef(compareArray);

  useEffect(() => {
    cArrayRef.current = compareArray;
  }, [compareArray])

  useEffect(() => {
    console.log('DATA', data);
  }, [data])

  const compareSelect = (id, checked) => {
    console.log(id, checked, cArrayRef.current);
    let compareArrayCopy = JSON.parse(JSON.stringify(cArrayRef.current));
    console.log(compareArrayCopy);
    if (checked) {
      compareArrayCopy.push(id);
    } else {
      compareArrayCopy = compareArrayCopy.filter((item) => item !== id);
    }
    console.log(compareArrayCopy);
    setCompareArray(compareArrayCopy);

  }

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: () => "ID",
        footer: (props) => props.column.id,
        id: "the_id",
      },
      {
        accessorKey: "contract_display_name",
        header: () => "Contract Name",
        footer: (props) => props.column.id,
        id: "contract_display_name",

      },

      {
        accessorFn: (row) => {
          return policyTypes.data.find((p) => p.id === row.policy_types_id)
            ?.type_name;
        },
        header: () => "Policy Type",
        footer: (props) => props.column.id,
        id: "policy_types_id",
      },
      {
        accessorFn: (row) => {
          return indeces.data.find((p) => p.Index_id === row.fuel_price_indeces_id)
            ?.index_name;
        },
        header: () => "Fuel Price Index",
        footer: (props) => props.column.id,
        id: "fuel_price_indeces_id",
      },
      {
        accessorFn: (row) =>
          `${row?.benchmark_price
            ? parseFloat(row.benchmark_price).toFixed(2)
            : "N/A"
          }`,
        header: () => "Benchmark Price",
        footer: (props) => props.column.id,
        id: "benchmark_price",
      },
      //   {
      //     accessorFn: (row) => `${row.baseline_price_comment}`,
      //     header: () => "Comment",
      //     footer: (props) => props.column.id,
      //     id: "benchmark_price_comment",
      //   },
      {
        accessorFn: (row) => `${row.update_frequency}`,
        header: () => "Update frequency",
        footer: (props) => props.column.id,
        id: "update_frequency",
      },
      {
        accessorFn: (row) => `${row.contract_display_notes}`,
        header: () => "Contract display notes",
        footer: (props) => props.column.id,
        id: "contract_display_notes",
      },
      {
        accessorFn: (row) => `${niceDate(row.contract_start_date)}`,
        header: () => "Contract start date",
        footer: (props) => props.column.id,
        id: "contract_start_date",
      },
      {
        accessorKey: "id",
        cell: ({ cell }) => {
          return (
            <input defaultChecked={compareArray.includes(cell.row.original.id)} type={"checkbox"} onChange={(event) => compareSelect(cell.row.original.id, event.target.checked)} className="form-checkbox h-4 w-4" />
          );
        },
        header: () => "Compare",
        footer: (props) => props.column.id,
        id: "compare_id",
      },
      {
        accessorKey: "id",
        cell: ({ cell }) => {
          return (
            <Link
              className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
              to={`/view-policy/${cell.row?.original.id}`}
            >
              View
            </Link>
          );
        },
        header: () => "",
        footer: (props) => props.column.id,
        id: "view_id",
      },
      {
        accessorKey: "id",
        cell: ({ cell }) => {
          return (
            <Link
              className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
              to={`/policies/edit/${cell.row?.original.id}`}
            >
              Edit
            </Link>
          );
        },
        header: () => "",
        footer: (props) => props.column.id,
        id: "edit_id",
      },
      //  {
      //     accessorFn: row => `${row.contract_display_name}`,
      //     id: 'contract_display_name',
      //     header: 'Contract Name',
      //     // cell: info => info.getValue(),
      //     // footer: props => props.column.id,
      //     filterFn: 'fuzzy',
      //     sortingFn: fuzzySort,
      // },
      // {
      //     accessorFn: row => row.lastName,
      //     id: 'lastName',
      //     cell: info => info.getValue(),
      //     header: () => <span>Last Name</span>,
      //     footer: props => props.column.id,
      // },
      // {
      //     accessorFn: row => `${row.firstName} ${row.lastName}`,
      //     id: 'fullName',
      //     header: 'Full Name',
      //     cell: info => info.getValue(),
      //     footer: props => props.column.id,
      //     filterFn: 'fuzzy',
      //     sortingFn: fuzzySort,
      // },

      // {
      //     accessorKey: 'contract_display_name',
      //     header: () => 'Age',
      //     footer: props => props.column.id,
      // },

      // {
      //     accessorKey: 'visits',
      //     header: () => <span>Visits</span>,
      //     footer: props => props.column.id,
      // },
      // {
      //     accessorKey: 'status',
      //     header: 'Status',
      //     footer: props => props.column.id,
      // },
      // {
      //     accessorKey: 'progress',
      //     header: 'Profile Progress',
      //     footer: props => props.column.id,
      // },
    ],
    []
  );

  // const [data, setData] = React.useState<Person[]>(() => makeData(50000))
  // const refreshData = () => setData(old => makeData(50000))

  console.log("SORTED", data);

  const table = useReactTable({
    data: data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  // React.useEffect(() => {
  //     if (table.getState().columnFilters[0]?.id === 'fullName') {
  //         if (table.getState().sorting[0]?.id !== 'fullName') {
  //             table.setSorting([{ id: 'fullName', desc: false }])
  //         }
  //     }
  // }, [table.getState().columnFilters[0]?.id])

  return (
    <div className="p-2">
      <div>
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="lg:w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 mt-2"
          placeholder="Search..."
        />
      </div>
      <div className="h-2" />
      <table className="w-full text-xs text-center text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className="px-4 py-4"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                className="bg-white hover:bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="px-4 py-4" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2 mt-4" />
      <div className="flex items-center gap-2">
        <button
          className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className=" bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-12 p-1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 text-sm rounded-lg text-base px-3 py-1 w-full sm:w-auto text-center"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>{table.getPrePaginationRowModel().rows.length} policies</div>

      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </div>
  );
};

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
