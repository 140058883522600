import { useEffect, useState } from "react";
import { useContractComparison } from "../api/queries";
import ComparisonTable from "./ComparisonTable";
import { useAtom } from "jotai";
import { comparePolicyArrayAtom } from "../state/state";

export const PageCompare = () => {

  const [compareArr] = useAtom(comparePolicyArrayAtom)

  // const [pArr, setPArr] = useState([])

  const comparison = useContractComparison({ idArray: compareArr});

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPArr([364, 363]) // show with 369

  //   }, 100)
  // }, [])

  console.log(comparison)

  return (
    <>
      <h1 className="text-2xl leading-none font-bold text-gray-900 mb-4">Compare</h1>

      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 pb-20 sm:pb-20 xl:pb-20 relative">

        <ComparisonTable data={comparison?.data} />

      </div>




    </>
  );
};

