import { useEffect, useState } from "react";
import { useGetFuelPriceIndeces, usePolicyTypes, useUserContracts } from "../api/queries";
import { Link } from "react-router-dom";



const RecentContracts = () => {

    const contracts = useUserContracts();
    const [recent, setRecent] = useState([])
    const types = usePolicyTypes();
    const indeces = useGetFuelPriceIndeces();

    useEffect(() => {
        if (contracts?.data?.length > 0 && recent.length === 0) {
            const tempForDemo =[];
            const copy = JSON.parse(JSON.stringify(contracts?.data));
            
            // copy.forEach(con => {
            //     if (con.id === 210 || con.id === 219) {
            //         tempForDemo.push(con);
            //     }
            // });
            
            const sorted = copy.sort((a, b) => {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.updated_at) - new Date(a.updated_at);
            });

            setRecent(sorted.slice(0,3));

        }
    }, [contracts, recent])

  

    return (
        <div class="w-full mb-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
            {recent.map(c => (
                <Link key={c.id} to={`/view-policy/${c.id}`} className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">

                    <div className="flex items-center">
                        <div className="">
                            <p className="text-xl leading-none font-bold text-gray-900 mb-4">{c.contract_display_name}</p>
                            <p className="text-sm font-normal text-gray-500 mb-2"><b>Last updated:</b> {new Date(c.updated_at).toLocaleDateString()}</p>
                            <p className="text-sm font-normal text-gray-500 mb-2"><b>Policy type:</b> {types?.data?.find(p => p.id === c.policy_types_id)?.type_name || 'none'}</p>
                            <p className="text-sm font-normal text-gray-500 mb-2"><b>Index name:</b> {indeces?.data?.find(p => p.Index_id === c.fuel_price_indeces_id)?.index_name || 'none'}</p>
                        </div>

                    </div>
                </Link>

            ))}

        </div>

    )

}

export default RecentContracts;