import { atom } from "jotai";
import { atomWithStorage } from 'jotai/utils'

export const userObjAtom = atom({});

export const tokenStringAtom = atomWithStorage('sessionToken', '');

export const fuelPriceIndecesObjAtom = atom(null);

export const newPolicyObjAtom = atom({});

export const comparePolicyArrayAtom = atom([]);

export const browserLangString = atom(window.navigator.language.includes('de') ? 'de' : 'en');